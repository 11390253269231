import React, { useEffect } from 'react';

export default function ProductLeftAd() {

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    // <!-- Producto 300x600 Izquierda Adap -->

    <ins className="adsbygoogle"
      style={{
        display: "inline-block",
        width: "200px",
        height: "600px"
      }}
      data-ad-client="ca-pub-9246481794007319"
      data-ad-slot="7017715205"></ins>


    // <ins className="adsbygoogle"
    //   style={{ display: "block" }}
    //   data-ad-client="ca-pub-9246481794007319"
    //   data-ad-slot="7017715205"
    //   data-ad-format="auto"
    //   data-full-width-responsive="true"></ins>
  );
}
