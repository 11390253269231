export const CREATE_AD_PENDING = "CREATE_AD_PENDING";
export const CREATE_AD_SUCCESS = "CREATE_AD_SUCCESS";
export const CREATE_AD_FAILED = "CREATE_AD_FAILED";
export const GET_USER_PRODUCTS_SUCCESS = "GET_USER_PRODUCTS_SUCCESS";

export const SET_PRODUCT_FILES = "SET_PRODUCT_FILES";

export const GET_ADS_PENDING = "GET_ADS_PENDING";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";
export const GET_ADS_FAILED = "GET_ADS_FAILED";

export const GET_AD_BY_ID_PENDING = "GET_AD_BY_ID_PENDING";
export const GET_AD_BY_ID_SUCCESS = "GET_AD_BY_ID_SUCCESS";
export const GET_AD_BY_ID_FAILED = "GET_AD_BY_ID_FAILED";

export const SEARCH_ADS_PENDING = "SEARCH_ADS_PENDING";
export const SEARCH_ADS_SUCCESS = "SEARCH_ADS_SUCCESS";
export const SEARCH_ADS_FAILED = "SEARCH_ADS_FAILED";

export const CLEAR_ADS_DATA = "CLEAR_ADS_DATA";

export const SHOW_MORE_ADS_LOADING = "SHOW_MORE_ADS_LOADING";