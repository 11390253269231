import React, { useState, useEffect, memo } from "react";

import MyLocationIcon from '@material-ui/icons/MyLocation';

import CloseIcon from '@material-ui/icons/Close';

import {
  GoogleMap,
  LoadScript,
  Marker
} from '@react-google-maps/api';

import { makeStyles, IconButton } from '@material-ui/core';


function Map({
  markerPosition, // array of [lng, lat]
  onLocationChange
}) {

  const [map, setMap] = React.useState(null)
  const [mapZoom, setMapZoom] = useState(5);
  const [showLocate, setShowLocate] = useState(null);

  const [openMap, setMapOpen] = useState(false);

  const [latLng, setLatLng] = useState({
    lat: 40.416775,
    lng: -3.703790
  });

  const [mapCenter, setMapCenter] = useState({
    lat: 40.416775,
    lng: -3.703790
  });


  useEffect(() => {
    if (markerPosition && markerPosition[0] && markerPosition[1]) {
      setMapCenter({
        lat: markerPosition[1],
        lng: markerPosition[0]
      });
      setLatLng({
        lat: markerPosition[1],
        lng: markerPosition[0]
      });
    }
  }, [markerPosition]);

  const mapClickHandler = (e) => {
    console.log(e.latLng.lat());
    console.log(e.latLng.lng());

    setLatLng({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
    onLocationChange({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });
  };

  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={mapZoom}
      onClick={mapClickHandler}
      onBoundsChanged={(e) => console.log('bounds chnage ', e)}

      options={{
        streetViewControl: false,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: true,
        navigationControl: true,
        fullscreenControl: false,
        draggable: true,
        zoomControl: true,
      }}
    >
      <Marker position={{ ...latLng }} name={'Current location'} />

    </GoogleMap>
  )
}

export default Map;