import React from 'react';
import actions from '../../redux/actions';
import { connect } from 'react-redux';

import {
  makeStyles,
  ClickAwayListener,
  Slider,
  withStyles,
} from '@material-ui/core';


import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';

import Map from '../Map';

const useStyles = makeStyles(theme => ({

  filters_section: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: '4.01rem',
    background: '#fff',
    zIndex: '10000',
    maxWidth: '100%',

    [theme.breakpoints.down("xs")]: {
      padding: 0
    },
  },

  arrow: {
    border: 'solid #253238',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: '3px',
    transform: 'rotate(45deg)',
    margin: '0 8px',
  },
  filter_button_arrow: {
    transition: 'transform .25s ease,-webkit-transform .25s ease',
    margin: '0 4px 3px',
    [theme.breakpoints.down("xs")]: {
      marginLeft: '0',
    },
  },
  filter_button_arrow_opened: {
    transform: 'rotate(-180deg)',
    marginBottom: '-10px',
  },

  location_container: {
    position: 'absolute',
    top: 60,
    right: 0,
    width: '30rem',
    overflow: 'hidden',
    padding: '.5rem 1.5rem .5rem',
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: '0 3px 16px 3px rgba(37,50,56,.2)',
    borderRadius: '10px',

    [theme.breakpoints.down("xs")]: {
      width: '20rem',
      // right: '10%',
      right: '-14%',
    },
  },

  location_header: {
    fontWeight: '700',
    color: '#222',
    fontSize: '17px',
    margin: '.5rem 0 1rem',
  },

  location_filters_button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '21px',
    boxShadow: '0 1px 4px 0 rgba(37,50,56,.1)',
    height: '36px',
    padding: '0 12px',
    maxWidth: '290px',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all .15s ease',
    margin: '1rem 1.5rem',
    textTransform: 'capitalize',

    '& > div': {
      paddingRight: '5px',
      paddingTop: '3px',
    },

    '& > div svg': {
      width: '15px',
      height: '15px',
    },

    '& > div svg path': {
      fill: '#737070',
    },

    [theme.breakpoints.down("xs")]: {
      margin: '1rem 0.2rem',
      padding: '0 7px',
      paddingRight: 0,
    },

    '&>span:nth-child(1)': {
      fontSize: '1rem',
      paddingRight: '5px',

      [theme.breakpoints.down("xs")]: {
        fontSize: '12px',
        paddingRight: '0px',
      },
    },

  },

  location_filter_title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: '12px',
      paddingRight: '0px',
    },
  },

  location_input_container: {
    display: 'flex',
    alignItems: 'center',
    margin: '.5rem 0 1rem',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    padding: '0 5px',

    '& span': {
      padding: '0 4px',

      '& >svg': {
        width: '20px',
        height: '18px',
      },

      '& > svg path': {
        fill: '#737070',
      },
    },

    '& input': {
      width: '100%',
      boxSizing: 'border-box',
      border: 'none',
      height: '35px',
      padding: '0 5px',

      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
  },

  location_slider_container: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-evenly',
    // color: '#959595',
    // paddingTop: '1rem',
    margin: '1rem .5rem 1rem 0',
  },
  location_slider: {
    display: 'flex',
    flexDirection: 'column',
    width: '8rem',
    margin: '1rem 1rem 1.5rem',
    textAlign: 'center',

    [theme.breakpoints.down("xs")]: {
      margin: '.5rem .5rem 1rem'
    },

    '&:hover': {
      color: '#c625d4',
      cursor: 'pointer',
    },
    '&:hover path': {
      fill: '#dd72e6',
    },
    '&:hover rect': {
      fill: '#dd72e6',
    }
  },

  location_filter_bottom_buttons: {

    margin: '1.5rem 0 .5rem',
    textAlign: 'right',

    '& button': {
      color: '#fff',
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      fontSize: '1rem',
      borderRadius: '2rem',
    },

    '& button:hover': {
      opacity: .7,
    },

    '& button:nth-child(1)': {
      background: 'transparent',
      color: '#253238',
    },

    '& button:nth-child(2)': {
      backgroundColor: '#cd2ed6',
      margin: '0 1rem',
      padding: '10px 32px',
    },
  },
}));


const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const marks = [
  {
    value: 1,
    label: '1 km',
  },
  {
    value: 2,
    label: '5 km',
  },
  {
    value: 3,
    label: '10 km',
  },
  {
    value: 4,
    label: '30 km',
  },
  {
    value: 5,
    label: '50 km',
  },
  {
    value: 6,
    label: '100 km',
  },
  {
    value: 7,
    label: '200 km',
  },
  {
    value: 8,
    label: 'Sin Limite'
  }
];

const mapMarksToRadius = (key) => {
  if (key > 8 || key < 1) {
    return -1;
  }
  const _map = {
    '1': 1,
    '2': 5,
    '3': 10,
    '4': 30,
    '5': 50,
    '6': 100,
    '7': 200,
    '8': 500,
  }

  return _map[key];
}

const DistanceSlider = withStyles({
  root: {
    color: '#cd2ed6',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#cd2ed6',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 14px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#cd2ed6',
  },
  mark: {
    backgroundColor: '#cd2ed6',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);


function LocationFilter({
  filterOpened,
  goTo
}) {

  const [openLocationFilter, setLocationFilterOpen] = React.useState(false);
  const [radiusKm, setRadiusKm] = React.useState(500);
  const [latLng, setLatLng] = React.useState([40.416775, -3.703790]);

  const [sliderValue, setSliderValue] = React.useState(8);

  const handleLocationFilterClick = () => {
    setLocationFilterOpen((prev) => !prev);
    filterOpened(!openLocationFilter);
  };

  const handleClickAway = () => {
    // console.log('click away...');
    setLocationFilterOpen(false);
    filterOpened(false);
  };

  const handleSliderChange = (e, value) => {
    // console.log(e, value);
    setSliderValue(value);
    const radiusKm = mapMarksToRadius(value);
    // console.log(radiusKm);
    setRadiusKm(radiusKm);
  }

  const handleApplyClick = () => {
    goTo(`/search?lat=${latLng[0]}&lng=${latLng[1]}&radius=${radiusKm * 1000}`);
    handleClickAway();
  }

  const radiusChangeHandler = (val) => {
    console.log('slider val..', val);
    for (let i = 1; i <= 9; i++) {
      const radius = mapMarksToRadius(i);
      if (radius === val) {
        setSliderValue(i);
      }
    }
  };

  const locationChangeHandler = (location) => {
    console.log('location change..', location);
    setLatLng([location.lat, location.lng]);
  };

  const classes = useStyles();

  return (
    <div>

      <div style={{ position: 'relative' }}>
        <div>
          <div className={classes.location_filters_button} onClick={handleLocationFilterClick}>
            <div><LocationIcon /></div>
            <span className={classes.location_filter_title}>Localización</span>
            <span className={`${classes.filter_button_arrow} ${openLocationFilter ? classes.filter_button_arrow_opened : ''}`}>
              <span className={classes.arrow}></span>
            </span>
          </div>

          {openLocationFilter ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={classes.location_container}>
                <div className={classes.location_header}>¿Dónde?</div>
                <div style={{ margin: 'auto' }}>

                  {/* <div className={classes.location_input_container}>
                    <span><SearchIcon /></span>
                    <input type="text" placeholder="¿Dónde?" />
                  </div> */}

                  <div className={classes.map_container}>
                    <Map radiusKm={radiusKm} onRadiusChange={radiusChangeHandler} onLocationChange={locationChangeHandler} />
                  </div>
                  <div className={classes.location_slider_container}>
                    {/* <Slider
                      defaultValue={30}
                      getAriaValueText={() => "Km"}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      step={10}
                      marks
                      min={10}
                      max={110}
                    /> */}
                    <DistanceSlider
                      step={null}
                      value={sliderValue}
                      marks={marks}
                      defaultValue={8}
                      min={1}
                      max={8}
                      onChange={handleSliderChange}
                    />
                  </div>
                  <div className={classes.location_filter_bottom_buttons}>
                    <button onClick={handleClickAway}>Cancelar</button>
                    <button onClick={handleApplyClick}>Aplicar</button>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps, actions)(LocationFilter);
