import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { TextField, Select, FormGroup, makeStyles, FormLabel, TextareaAutosize, InputLabel, MenuItem, Box, LinearProgress } from "@material-ui/core";
import firebase from '../utils/firebase';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { Dropzone } from './Dropzone';

import { memo } from "react";


const useStyles = makeStyles((theme) => ({
  'form': {
    // [theme.breakpoints.down("xs")]: {
    //   padding: 0
    // },
    '& .MuiFormGroup-root': {
      margin: '10px 0',
    },
    '& .MuiFormControl-root': {
      marginRight: '20px',
    },
    width: '100%'
  },
  form_label: {
    margin: "10px 0",
  },
  drop_item: {
    width: '145px',
    height: '120px',
    marginRight: '10px',
  },
  drop_item_add_file: {
    border: '2px dashed #7258b0',
    backgroundColor: '#edebfd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    outlineColor: '#5b38ba',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  }
}));

const db = firebase.firestore();

function CreateAdForm({
  userId,
  createAd,
  createAdPending,
  productFiles,
  setProductFiles,
}) {


  console.log('CreateAdForm render...')

  const classes = useStyles();

  // // clean up
  // useEffect(() => () => {
  //   files.forEach(file => URL.revokeObjectURL(file.preview));
  // }, [files]);



  const formSubmitHandler = (values) => {
    console.log(values)
    console.log(productFiles);
    createAd({ ...values, media: productFiles, price: +values.price });
  };

  console.log('re-render');

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  // Validation schema
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, "Mínimo 3 símbolos")
      .max(50, "Máximo 50 símbolos")
      .required("el título es obligatorio"),
    description: Yup.string()
      .min(5, "Mínimo 5 símbolos")
      .required("se requiere descripción"),
    price: Yup.number()
      .required("el precio es requerido"),
    categories: Yup.string().min(1, "las categorías son obligatorias").required("las categorías son obligatorias"),
    subcategories: Yup.string().min(1, "las subcategorías son obligatorias").required("las subcategorías son obligatorias"),
    // address: Yup.string().min(1, "Habla a son obligatorias").required("Habla a son obligatorias"),
  });

  const { control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    validationSchema: validationSchema,
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (values) => {
    // console.log(files);
    formSubmitHandler(values);
  };

  useEffect(() => {
    if (categories.length <= 0) {
      (async () => {
        const categoriesDoc = await db.doc('configuration/categories').get();
        const categories = await categoriesDoc.data();

        console.log(categories.data);
        setCategories(categories.data);
      })();
    }
  }, []);

  const categorySelectHandler = (value) => {
    setTimeout(() => {
      console.log(value, getValues())
      if (categories && categories.length > 0) {
        const subCategories = categories?.filter((el) => getValues().categories && el.main === getValues().categories);

        if (subCategories) {
          setSubCategories(subCategories[0]?.sub);
          setValue('subcategories', ''); //re-initialize subcategories
        }
      }
    }, 0)
  }


  // console.log(errors)
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
      <FormGroup style={{ margin: '0 0 20px' }}>
        <Dropzone productFiles={productFiles} setProductFiles={setProductFiles} />
      </FormGroup>

      <FormGroup row={true}>
        <FormControl style={{ minWidth: 150 }}>
          <InputLabel id="categories-label">Categorías</InputLabel>

          <Controller
            name="categories"
            control={control}
            render={({ field }) =>
              <Select onClickCapture={categorySelectHandler} {...field} labelId="categories-label" name="categories" label="Categoría"
                error={!!errors.categories}
                helperText={errors.categories?.message}
              >
                {categories?.map((category) => (
                  <MenuItem key={category.main + "!"} value={category.main}
                  // selected={formik.values?.categories && category.main === formik.values?.categories[0] ? "selected" : false}
                  >
                    {category.main}
                  </MenuItem>
                ))}
              </Select>}
          />
        </FormControl>

        <FormControl style={{ minWidth: 150 }}>
          <InputLabel id="subcategories-label">Sub Categorías</InputLabel>

          <Controller
            name="subcategories"
            control={control}
            render={({ field }) =>
              <Select  {...field} labelId="subcategories-label" name="subcategories" label="Sub Categoría"
                error={!!errors.subcategories}
                helperText={errors.subcategories?.message}
                placeholder="Sub Categoría">
                {subCategories?.map((subCategory) => (
                  <MenuItem key={subCategory} value={subCategory}>
                    {subCategory}
                  </MenuItem>
                ))}
              </Select>}
          />
        </FormControl>
      </FormGroup>

      <FormGroup>
        <Controller
          name="price"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <TextField
            {...field}
            type="number"
            name="price"
            placeholder="Precio"
            label="Precio"
            customFeedbackLabel="Por favor ingrese el precio"
            error={!!errors.price}
            helperText={errors.price?.message}
          />}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          name="title"
          control={control}
          defaultValue=""
          render={({ field }) => <TextField
            {...field}
            name="title"
            placeholder="Título"
            label="Título"
            error={!!errors.title}
            helperText={errors.title?.message}
          />}
        />
      </FormGroup>

      <FormGroup>
        <FormControl>
          <FormLabel className={classes.form_label}>Descripción</FormLabel>
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => <TextareaAutosize
              name="description"
              rowsMin={8}
              variant="outlined"
              {...field}
            />}
          />
          <p className="MuiFormHelperText-root Mui-error">{errors.description?.message}</p>
        </FormControl>
      </FormGroup>
      {/* <FormGroup>
        <Controller
          name="address"
          control={control}
          defaultValue=""
          render={({ field }) =>
            <TextField
              disabled={true}
              name="address"
              placeholder="Habla a"
              label="Habla a"
              {...field}
              variant="standard"
              error={!!errors.address}
              helperText={errors.address?.message}
            />}
        />
      </FormGroup> */}
      {/* <FormGroup>
        <Controller
          name="coordinates"
          control={control}
          defaultValue=""
          render={({ field }) =>
            <TextField
              name="coordinates"
              placeholder="Coordenadas -3.705108557386272,40.416157459924264"
              label="Coordenadas"
              {...field}
              variant="outlined"
            error={errors.coordinates}
            helperText={errors.coordinates?.message}
            />}
        />
      </FormGroup> */}
      {/* <FormGroup style={{ margin: '20px 0' }}>
        <FormControl>
          <LocationMap onLocationChange={locationChangeHandler} />
        </FormControl>
      </FormGroup> */}
      <FormGroup>
        <FormControl>
          <Button className='btn-primary' color="primary" type="submit" variant="contained" disabled={createAdPending}>
            {createAdPending ? <CircularProgress style={{ color: '#fff' }} size={24} /> : 'Publicar anuncio'}
          </Button>
        </FormControl>
      </FormGroup>
    </form>
  );
}

export default memo(CreateAdForm);