import { Route } from "react-router";
import Home from "../containers/Home/index";
import Search from '../components/Search';
import AdDetail from '../components/AdDetail';
import PrivateRoute from "./PrivateRoute";


export default (
  <>
    <Route path="/" exact component={Search} />
    <Route path="/search" exact component={Search} />
    <Route path="/ad" exact component={AdDetail} />
    <PrivateRoute path="/profile" exact component={AdDetail} />
  </>
);