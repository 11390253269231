import React, { useEffect, useState } from 'react';
import Ads from '../containers/Ads/index';

import actions from '../redux/actions';
import { connect } from 'react-redux';

function Search(props) {

  const [searchParams, setSearchParams] = useState({
    category: ''
  });

  useEffect(() => {

    // console.log(props.location);

    const { searchTextChange } = props;

    const params = new URLSearchParams(props.location.search);
    let category = params.get('category');
    let lat = params.get('lat');
    let lng = params.get('lng');
    let radius = params.get('radius');


    if (!category) {
      category = "Todas las categorías";
    }

    setSearchParams({
      category: category,
      lat,
      lng,
      radius
    });

    // searchTextChange(category);
  }, [props.location]);

  return (
    <div>
      <Ads searchParams={searchParams} />
    </div>
  );
}


const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps, actions)(Search);
