import React from 'react';
import { connect } from 'react-redux';
import actions from '../redux/actions';

import {
  makeStyles,
  Container,
  Divider,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  footer_categories_container: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: '2rem',
    padding: '2rem 0',

    [theme.breakpoints.down("xs")]: {
      justifyContent: 'space-between',
    },
  },
  footer_categories: {
    minWidth: '12rem',

    [theme.breakpoints.down("xs")]: {
      minWidth: '0',
      width: '9rem',
      marginBottom: '.5rem',
    },
  },
  category_title: {
    margin: '10px 0',
    fontWeight: '700',
    fontSize: '15px',
    color: '#607D8B',
    textTransform: 'capitalize',

    [theme.breakpoints.down("xs")]: {
      worldBreak: 'break-all',
    },

    '&:hover': {
      cursor: 'pointer',
      color: '#6a4ee1',
    }
  },
  footer_subcategories: {
    color: '#90A4AE',
    fontSize: '14px',
    fontWeight: '600',

    [theme.breakpoints.down("xs")]: {
      worldBreak: 'break-all',
    },

    '&:hover': {
      cursor: 'pointer',
      color: '#6a4ee1',
    }
  },
}));

function FooterCategories({
  goTo,
  categories,
}) {


  const footerCategoryClickHandler = (category) => {
    const isSearch = window.location.pathname.indexOf('search') > -1;
    console.log(isSearch);

    window.scrollTo({
      top: 0,
      behavior: isSearch ? 'smooth' : 'auto',
    });

    goTo(`/search?category=${category}`);

  }

  const classes = useStyles();

  return (
    <div>
      <Divider />

      <Container>
        <div className={classes.footer_categories_container}>
          {
            categories.map((category, i) => {
              if (i !== 0)
                return (
                  <div key={i} className={classes.footer_categories}>
                    <div className={classes.category_title} onClick={() => footerCategoryClickHandler(category.title)}>{category.title}</div>
                    {
                      category.sub
                        ?
                        category.sub.map((sub, i) => {
                          return (
                            <div key={i} className={classes.footer_subcategories} onClick={() => footerCategoryClickHandler(sub)}>{sub}</div>
                          )
                        })
                        : <></>
                    }
                  </div>
                )
            })
          }
        </div>
      </Container>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    "categories": state.home.categories,
  }
}

export default connect(mapStateToProps, actions)(FooterCategories);