import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";

import { Avatar, CircularProgress, Grid, Link, } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import actions from '../../redux/actions';
import FacebookIcon from '@material-ui/icons/Facebook';

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  container: {
    width: '500px',
    padding: "20px 0 20px",

    [theme.breakpoints.down('xs')]: {
      padding: '0px',
      width: '100%',
    },
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
});

function LogIn({
  loginUser,
  loginWithFacebook,
  loginWithGoogleUser,
  classes,
  isLoggingIn,
  loginError,
  authDialogToggle,
  setShowSignup,
}) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value)
  };

  const handleSubmit = () => {
    loginUser(email, password);
  };

  const handleFacebookSubmit = () => {
    loginWithFacebook(email, password);
  };

  const handleGoogleSubmit = () => {
    loginWithGoogleUser(email, password);
  };

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Acceso
          </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="email"
        label="Dirección de correo electrónico"
        name="email"
        onChange={handleEmailChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="password"
        label="Contraseña"
        type="password"
        id="password"
        onChange={handlePasswordChange}
      />
      {loginError && (
        <Typography component="p" className={classes.errorText}>
          Correo o contraseña incorrectos.
        </Typography>
      )}
      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleSubmit}
      >
        {isLoggingIn ? <CircularProgress color="secondary" size={24} /> : 'Acceso'}
      </Button>
      <Grid container justify="flex-end">
        <Grid item>
          <Link style={{ outline: 'none', cursor: 'pointer', margin: '10px 0' }} variant="body2" onClick={() => setShowSignup(true)}>
            Cree una cuenta en su lugar
            </Link>
        </Grid>
      </Grid>
      <Button
        type="button"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleFacebookSubmit}
        startIcon={<FacebookIcon />}
      >
        entra con Facebook
          </Button>

      <Button
        type="button"
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.submit}
        onClick={handleGoogleSubmit}
        startIcon={<FacebookIcon />}
      >
        entra con Google
          </Button>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
  };
}

export default withStyles(styles)(connect(mapStateToProps, actions)(LogIn));