import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAylvogh6U1Y4FirRdKF_V6_2FlUVKmINk",
  authDomain: "anunciavi-2019.firebaseapp.com",
  databaseURL: "https://anunciavi-2019.firebaseio.com",
  projectId: "anunciavi-2019",
  storageBucket: "anunciavi-2019.appspot.com",
  messagingSenderId: "561374694006",
  appId: "1:561374694006:web:b6bacddce5dc6b07ed6d57",
  measurementId: "G-RDN6YTRZQX"
};

firebase.initializeApp(firebaseConfig);

export default firebase;