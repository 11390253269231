import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { persistor, history } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import routes from './routes/routes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Header from './containers/Header';
import Auth from './containers/Auth';
import Footer from './components/Footer';
import CookieConsentBanner from './components/CookieConsentBanner';
// smoothscroll for safari
import smoothscroll from 'smoothscroll-polyfill';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  LoadScript,
} from '@react-google-maps/api';

// kick off the polyfill!
smoothscroll.polyfill();

const libraries = ["places"];

function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'SFCompactDisplay', '"Source Sans Pro"',
        'Helvetica',
      ].join(','),
      color: '#253238'
    },
  });

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading="none" persistor={persistor}>
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              libraries={libraries}
            >
              <Header />
              <ConnectedRouter history={history}>
                <Auth />
                <>{routes}</>
              </ConnectedRouter>
              <Footer />
              <CookieConsentBanner />
              <ToastContainer />
            </LoadScript>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
