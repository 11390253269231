import React, { useState, useEffect, useCallback } from "react";
import { useDropzone, } from 'react-dropzone';
import { getVideoCover } from '../utils/getVideoCover';
import { Cancel as CancelIcon, Add } from '@material-ui/icons';
import { Grid, Select, FormGroup, makeStyles, Box, LinearProgress } from "@material-ui/core";
import firebase from '../utils/firebase';


const useStyles = makeStyles((theme) => ({
  'form': {
    '& .MuiFormGroup-root': {
      margin: '10px 0',
    },
    '& .MuiFormControl-root': {
      marginRight: '20px',
    }
  },
  form_label: {
    margin: "10px 0",
  },
  drop_item: {
    width: '145px',
    height: '120px',
    // marginRight: '10px',
  },
  drop_item_add_file: {
    border: '2px dashed #7258b0',
    backgroundColor: '#edebfd',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    outlineColor: '#5b38ba',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  }
}));

export const Dropzone = React.memo(({ productFiles, setProductFiles }) => {
  const [imageUploading, setImageUploading] = useState(false);

  const classes = useStyles();

  const removeImageHandler = (file) => {
    console.log(file);
    const filePath = file.split("|");

    const selectedFiles = productFiles.filter((file, i) => {
      console.log(file, filePath)
      return filePath[1] !== file.path.split('|')[1];
    });
    console.log(selectedFiles)
    if (selectedFiles && selectedFiles.length > 0) {
      setProductFiles([...selectedFiles]);
    } else {
      setProductFiles([]);
    }
  }

  console.log(productFiles);

  const thumbs = productFiles.map((file, i) => {
    console.log(file.path?.split("|"))
    const isThumbnail = file.path?.split("|")[0] === "thu";

    if (isThumbnail) {
      return (
        <Grid item key={file.name} className={classes.drop_item}>
          <div style={{
            position: "relative",
            width: "100%",
            height: "100%",
            backgroundColor: '#eee',
          }}>
            <img
              width="100%"
              height="100%"
              src={file.url}
              alt={file.name}
            />
            <div style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              cursor: "pointer",
              borderRadius: "100%",
              color: "rgb(147 122 122)",
              backgroundColor: "white",
              display: "flex",
            }}>
              <CancelIcon onClick={() => removeImageHandler(file.path)} />
            </div>
          </div>
        </Grid>
      )
    }
    return null;
  });


  const onDrop = uploadedFile => {
    console.log(uploadedFile);
    console.log(productFiles);

    const isVideo = uploadedFile[0].type.split("/")[0] === "video";
    const extension = uploadedFile[0].type.split("/")[1];
    console.log("isVideo ", isVideo, extension);
    setImageUploading(true);

    (async () => {
      try {
        const filePath = `${Date.now()}_${new Date().toISOString()}`;
        const userId = firebase.auth().currentUser?.uid;

        if (isVideo) {
          const videoCover = await getVideoCover(uploadedFile[0]);
          const videoCoverExtension = videoCover.type.split("/")[1];
          const videoCoverSnapshot = await firebase.storage().ref(`/ads/${userId}/${filePath}.${videoCoverExtension}`).put(videoCover);

          const videoCoverURL = await videoCoverSnapshot.ref.getDownloadURL();

          console.log(videoCover);
          console.log(videoCoverSnapshot);

          const videoSnapshot = await firebase.storage().ref(`/ads/${userId}/${filePath}.${extension}`).put(uploadedFile[0])
          console.log(videoSnapshot);

          const videoURL = await videoSnapshot.ref.getDownloadURL();

          console.log([...productFiles, { videoURL: videoURL }]);

          setProductFiles([...productFiles, { url: videoURL, path: `vid|${filePath}` }, { url: videoCoverURL, path: `thu|${filePath}` }]);

          setImageUploading(false);

        } else {

          const snapshot = await firebase.storage().ref(`/ads/${userId}/${filePath}.${extension}`).put(uploadedFile[0]);
          console.log(snapshot)

          const downloadURL = await snapshot.ref.getDownloadURL();
          // console.log([...productFiles, { url: downloadURL }]);
          console.log(productFiles);
          setProductFiles([...productFiles, { url: downloadURL, path: `img|${filePath}` }, { url: downloadURL, path: `thu|${filePath}` }]);

          setImageUploading(false);
        }
      } catch (err) {
        console.log(err);
        setImageUploading(false);
      }
    })();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: 'image/*, video/*' })

  return (
    <Box className="p-3" minHeight={100}>
      <Grid container wrap spacing={2}>
        {thumbs}
        <Grid item className={`${classes.drop_item}`}>
          <div className={classes.drop_item_add_file} {...getRootProps()}>
            <input disabled={imageUploading} {...getInputProps()} />
            {
              isDragActive ?
                <p class="m-3">Suelta el archivo aquí ...</p> :

                imageUploading ? (
                  <>
                    <div style={{ width: '80%', margin: '10px auto' }}><LinearProgress /></div>
                    <div>Subiendo...</div>
                  </>
                ) : (
                    <>
                      <div><Add color="#9b59cc" /></div>
                      <div style={{ textAlign: 'center' }}>Subir vídeos / fotos </div>
                    </>
                  )
            }
          </div>
        </Grid>
      </Grid >
    </Box >
  );
});