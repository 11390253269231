import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ReactComponent as StarIcon } from "../assets/icons/star.svg"
import firebase from '../utils/firebase';
import {
  makeStyles,
  Grid,
  Container,
  Button,
  IconButton,
  SvgIcon,
  CircularProgress,
  Box
} from '@material-ui/core';

import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg';
import { ReactComponent as EyeIcon } from '../assets/icons/eye.svg';
import { ReactComponent as LocationIcon2 } from '../assets/icons/location2.svg';

import PersonImage from '../assets/img/person.png';

import { format } from 'date-fns';

import Carousel from 'react-multi-carousel';
import actions from '../redux/actions';
import { connect } from 'react-redux';
import Map from './Map';

import { getGeoLocation } from '../utils/geoLocation';

import ProductHeaderAd from '../components/googleAds/ProductHeaderAd';
import ProductLeftAd from '../components/googleAds/ProductLeftAd';
import ProductRightAd from '../components/googleAds/ProductRightAd';
import ProductRightAdMobile from '../components/googleAds/ProductRightAdMobile';

import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import ProductHeaderAdMobile from './googleAds/ProductHeaderAdMobile';

const useStyles = makeStyles(theme => ({
  ad_details_container: {
    paddingTop: '4rem',
    backgroundColor: '#f5f5ff',
    minHeight: '500px',
  },
  top_section_items_container: {
    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
  },

  progress_bar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '76vh',
  },
  ad_item_header_container: {
    position: 'sticky',
    top: '4rem',
    // top: '6.5rem',
    zIndex: '10000',
    background: '#fff',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '1rem 1rem',
    boxSizing: 'border-box',
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
  },
  ad_item_header_details: {
    display: 'flex',
    width: '17.5rem',

    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },

  },
  ad_item_header_image: {
    width: '3rem',
    height: '3rem',
    marginRight: '1rem',

    '& img': {
      borderRadius: '50%',
      width: '100%',
      height: '100%',
    },
  },
  ad_item_header_text: {
    '&>div': {
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    '&>span': {
      fontSize: '13px',
    },
  },
  ad_item_header_right: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  ad_item_header_rating: {
    textAlign: 'center',

    [theme.breakpoints.down("xs")]: {
      display: 'none',
    },

    '&>span': {
      fontSize: '13px',
      color: '#7f9099',
    },

    '& svg path': {
      fill: '#ffdf43',
    },
  },
  ad_item_header_chat_btn: {
    '& button': {
      backgroundColor: '#7300bd',
      borderRadius: '20px',

      '& span': {
        color: '#fff',
        textTransform: 'capitalize',
      }
    },
    '& button:hover': {
      backgroundColor: '#7300bd',
      opacity: .8
    }
  },
  ad_item: {
    // width: '15rem',
    // height: '30rem',
    overflow: 'hidden',
    padding: '.5rem .8rem',
    backgroundColor: '#fff',
    marginBottom: '2rem',
    borderBottomLeftRadius: '14px',
    borderBottomRightRadius: '14px',
    boxSizing: 'border-box',

    [theme.breakpoints.down("xs")]: {
      width: '100%',
    },
  },
  ad_item_img_carousel: {

    margin: 'auto',

    [theme.breakpoints.down("xs")]: {
      width: '20rem',
    },
  },
  ad_item_media: {
    width: '100%',
    height: '28rem',
    backgroundColor: '#222',

    [theme.breakpoints.down("xs")]: {
      height: '15rem',
    },

    '& img': {
      width: '100%',
      height: '100%',
    },

    '& video': {
      width: '100%',
      height: '100%',
    },
    '& video:focus': {
      border: 'none'
    }
  },
  ad_item_details: {
    padding: '0 5px 10px 5px',

    // '& span': {
    //   fontSize: '14px',
    //   color: '#607D8B',
    //   textTransform: 'lowercase',
    // }
  },
  price: {
    fontWeight: '700',
    fontSize: '1.8rem',
    padding: '8px 0 2px',
    color: '#ef6c25',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '700',
    padding: '0 0 20px',
    textTransform: 'capitalize',
  },
  ad_category_container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 1rem 1rem 0',
    borderTop: '1px solid #e8ecef',
  },
  ad_category: {
    '& span:nth-child(1)': {
      textTransform: 'capitalize',
      color: '#7e7e7e',
    },

    '& span:nth-child(2)': {
      fontWeight: 600,
      textTransform: 'capitalize',
    },
  },
  ad_state: {
    '& span:nth-child(1)': {
      textTransform: 'capitalize',
      color: '#7e7e7e',
    },

    '& span:nth-child(2)': {
      fontWeight: 600,
    },
  },
  ad_description: {
    fontSize: '1rem',
    fontWeight: '600',
    padding: '1rem 0',
    borderTop: '1px solid #e8ecef',
  },
  ad_post_date_container: {
    padding: '1rem 1rem 1rem 0',
    borderTop: '1px solid #e8ecef',
    display: 'flex',
    justifyContent: 'space-between',
  },
  ad_post_date: {
    fontSize: '19px',
    color: '#7e7e7e',
  },
  ad_views: {
    color: '#7e7e7e',
    margin: 'auto 0',

    '& span:nth-child(1)': {
      paddingRight: '5px',
      verticalAlign: 'middle',
    }
  },

  ad_location_container: {
    borderTop: '1px solid #e8ecef',
    margin: '0 0 1.5rem',
  },
  ad_location_header: {
    padding: '1rem 1rem 1rem 0',
    display: 'flex',
    alignItems: 'center',

    '& span:nth-child(1)': {
      paddingRight: '1rem',

      '& svg': {
        width: '25px',
        height: '25px',
      }
    },

    '& span:nth-child(2)': {
      textTransform: 'capitalize',
      fontSize: '17px',
      fontWeight: '600',
    },
  },


  ad_banner_left: {
    maxWidth: '300px',
    width: '100%',
    height: '600px',
    position: 'sticky',
    top: '6rem',
    margin: '8px 0 2rem',
    // border: '1px solid',

    [theme.breakpoints.down("sm")]: {
      display: 'none',
    },
  },
  ad_banner_right: {
    maxWidth: '300px',
    width: '100%',
    height: '600px',
    position: 'sticky',
    top: '6rem',
    margin: '8px 0 2rem',
    [theme.breakpoints.down("xs")]: {
      height: '100px',
      maxWidth: "100%",
    },
  },

  footer_social_icon: {
    color: '#7300bd',
    width: '40px',
    height: '40px',
  }
}));


function AdDetail(props) {

  const [data, setData] = useState(null);
  const [adId, setAdId] = useState(null);
  const [adViews, setAdViews] = useState(0);


  const { ads, getAdById, adData, getAdByIdPending, getUserProducts, userProductsCount } = props;

  useEffect(() => {

    // console.log(props.location);
    const params = new URLSearchParams(props.location.search);
    const id = params.get('id');
    setAdId(id);
    // console.log(id);
    // console.log(ads);

    if (ads && ads.length > 0) {
      const ad = ads.filter((ad) => {
        return ad._id === id;
      })[0];
      // console.log(ad);

      if (ad) {
        setData(ad);
        getUserProducts(ad.userId);
      }
    }

    // return () => {

    // }
  }, [ads]);

  useEffect(() => {
    if (!(ads && ads.length > 0)) {
      const params = new URLSearchParams(props.location.search);
      const id = params.get('id');
      setAdId(id);
      getAdById(id);
    }
    // console.log(process.env);
  }, []);

  // scroll to the share section if share button is pressed in previous screen
  useEffect(() => {
    if (props.location.state && props.location.state.share) {
      const timeOut = setTimeout(() => {
        window.scrollTo({
          top: 760,
          behavior: 'smooth'
        });
      }, 500);

      return () => {
        clearTimeout(timeOut);
      }
    }
  }, []);

  useEffect(() => {
    if (!(ads && ads.length > 0)) {
      if (adData) {
        setData(adData);
        getUserProducts(adData.userId);
      }
    }

  }, [adData]);

  useEffect(() => {
    if (data) {
      firebase.firestore().collection('ads').doc(data._id).get()
        .then((doc) => {
          // console.log(doc.data());
          if (!doc.data()) throw new Error("");
          setAdViews(doc.data()?.views);
        })
        .catch(err => {
          console.log('Error while fetching ad views...', err);
        })
    }
  });

  // console.log(data);

  const classes = useStyles();


  const isVideo = (path) => {
    if (typeof path === "string") {
      const data = path.split("|");
      if (data[0] === "vid") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
  const dateFormat = (d) => {
    const date = new Date(d);
    const formattedDate = format(date, "dd-MMM-yyyy ");
    return formattedDate;
  };

  if (!data) {
    return <></>;
  }

  const getMedia = (media) => {
    console.log(media);

    const filteredMedia = media.filter(media => {
      if (typeof media.path === "string") {
        const path = media.path.split("|");
        if (path[0] !== "thu") {
          return true;
        }
      }
      return false;
    });
    return filteredMedia;
  }

  const getFormattedAddress = (address) => {
    if (typeof address === "string") {
      const addressArr = address.split(',');
      if (addressArr.length > 2) {
        return `${addressArr[addressArr.length - 3] || ''}, ${addressArr[addressArr.length - 2]}, ${addressArr[addressArr.length - 1]}`;
      } else {
        return address;
      }
    }
    return "";
  }

  return (
    <div className={classes.ad_details_container}>

      <Container>
        <Grid container justify="center">
          <Grid item>
            <div style={{
              position: 'sticky',
              top: '3rem',
              zIndex: '10000',
              background: '#f5f5ff',
              maxHeight: '100px',
              padding: '2rem 0',
              width: '100%'
            }}>
              <Box
                display={{ xs: 'none', sm: 'block' }}
                style={{
                  maxHeight: '300px',
                  textAlign: 'center'
                  // border: '1px solid',
                }}>
                <ProductHeaderAd />
              </Box>

              <Box
                display={{ xs: 'block', sm: 'none' }}
                style={{
                  maxHeight: '300px',
                  textAlign: 'center',
                  // border: '1px solid',
                }}>
                <ProductHeaderAdMobile />
              </Box>

            </div>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item md={2}>
            <div className={classes.ad_banner_left}>
              <ProductLeftAd />
            </div>
          </Grid>
          <Grid item sm={8} md={8} lg={6}>
            {
              getAdByIdPending ? (
                <div className={classes.progress_bar}>
                  <CircularProgress />
                </div>
              )

                :
                <div className={classes.top_section_items_container}>

                  <div className={classes.ad_item_header_container}>
                    <div className={classes.ad_item_header_details}>
                      <div className={classes.ad_item_header_image}>
                        <img src={data.picture ? data.picture : PersonImage} alt="" />
                      </div>
                      <div className={classes.ad_item_header_text}>
                        <div>{data.name}</div>
                        <span>{userProductsCount} {userProductsCount === 1 ? 'Producto' : 'Productos'} </span>
                      </div>
                    </div>
                    <div className={classes.ad_item_header_right}>
                      <div className={classes.ad_item_header_rating}>
                        {/* <div>
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                        </div>
                        <span>3 Valoraciones</span> */}
                      </div>
                      <div className={classes.ad_item_header_chat_btn}>
                        <Button className={classes.show_more_button} variant="contained" onClick={() => window.location = "http://apps.anunciavi.com/"}>
                          Chat
                    </Button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.ad_item}>

                    <div className={classes.ad_item_img_carousel}>
                      {/* <img src={data.media[1].url} alt="add img" /> */}
                      <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1024
                            },
                            items: 1,
                            partialVisibilityGutter: 40
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                          }
                        }}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                      >

                        {
                          data.media ? getMedia(data.media).map((media, i) => {
                            if (isVideo(media.path)) {
                              return (
                                <div key={i} className={classes.ad_item_media}>
                                  {/* @TODO add poster image when video is being loaded */}
                                  <video controls>
                                    <source src={media.url} type="video/mp4" />
                                  </video>
                                </div>
                              );
                            }
                            return (
                              <div key={i} className={classes.ad_item_media}>
                                <img src={media.url} alt="img" />
                              </div>
                            );
                          }) : ""
                        }

                      </Carousel>
                    </div>
                    <div className={classes.ad_item_details}>
                      <div className={classes.price}> {data.price}&nbsp;&#8364;</div>
                      <div className={classes.title}>{data.title}</div>

                      <div className={classes.ad_category_container}>
                        <div className={classes.ad_category}>
                          <span>Tipo: </span>
                          <span>{data.categories[0]}</span>
                        </div>
                        <div className={classes.ad_state}>
                          <span>Estado: </span>
                          <span>Como nuevo</span>
                        </div>
                      </div>
                      <div className={classes.ad_description}>{data.description}
                      </div>
                      <div className={classes.ad_post_date_container}>
                        <div className={classes.ad_post_date}>
                          <span>{dateFormat(data.postedat)}</span>
                          <span></span>
                        </div>
                        <div className={classes.ad_views}>
                          <span><EyeIcon /></span>
                          <span>{adViews}</span>
                        </div>
                      </div>

                      <div className={classes.ad_location_container}>
                        <div className={classes.ad_location_header}>
                          <span><LocationIcon2 /> </span>
                          <span>{getFormattedAddress(data.address)}</span>
                        </div>
                        <div style={{ minHeight: '200px' }}>
                          <Map coordinates={data.location?.coordinates} radiusKm={1} mapOverlay={true} isMainMap={false} />
                        </div>
                      </div>

                      <div>
                        <div style={{
                          textAlign: 'center',
                        }}>
                          Comparte este producto con tus amigos
                        </div>
                        <div style={{
                          textAlign: 'center',
                          letterSpacing: '1px',
                          padding: '1rem 0',
                        }}>

                          <FacebookShareButton url={`${process.env.REACT_APP_WEB_URL}/ad?id=${adId}`} >
                            <IconButton >
                              <FacebookIcon className={classes.footer_social_icon} />
                            </IconButton>
                          </FacebookShareButton>
                          {/* <IconButton >
                          <InstagramIcon className={classes.footer_social_icon} />
                        </IconButton>  */}

                          <TwitterShareButton title="Mira que acabo de encontrar en" url={`${process.env.REACT_APP_WEB_URL}/ad?id=${adId}`}>
                            <IconButton >
                              <TwitterIcon className={classes.footer_social_icon} />
                            </IconButton>
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </Grid>
          <Grid item xs={12} md={2}>
            <Box className={classes.ad_banner_right}
              display={{ xs: 'none', sm: 'block' }}
            >
              <ProductRightAd />
            </Box>

            <Box
              className={classes.ad_banner_right}
              display={{ xs: 'block', sm: 'none' }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ProductRightAdMobile />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div >
  );
}

const mapStateToProps = state => {
  return {
    'ads': state.ads.data,
    'adData': state.ads.adData,
    'getAdByIdPending': state.ads.getAdByIdPending,
    userProductsCount: state.ads.userProductsCount,
  }
}

export default connect(mapStateToProps, actions)(AdDetail);
