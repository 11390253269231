import React, { useState, useEffect } from "react";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Circle,
//   Marker,
// } from "react-google-maps";
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';


import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";
import CloseIcon from '@material-ui/icons/Close';

import {
  GoogleMap,
  LoadScript,
  Circle,
} from '@react-google-maps/api';

import { makeStyles, IconButton } from '@material-ui/core';

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const useStyles = makeStyles(theme => ({


  location_input_container: {
    display: 'flex',
    alignItems: 'center',
    margin: '.5rem 0 1rem',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    padding: '0 5px',

    '& span': {
      padding: '0 4px',

      '& >svg': {
        width: '20px',
        height: '18px',
      },

      '& > svg path': {
        fill: '#737070',
      },
    },

    '& input': {
      width: '100%',
      boxSizing: 'border-box',
      border: 'none',
      height: '35px',
      padding: '0 5px',

      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
  },
  combobox_popover: {
    zIndex: '10000',
    // width: '477px',
    // left: '313px !important',

    '& li': {
      padding: '.9rem 1.5rem',
      borderBottom: '1px solid #eceff1',
    }
  }
}));


const libraries = ["places"];



function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: { lat: () => 43.6532, lng: () => -79.3832 },
      // radius: 100 * 1000,
      componentRestrictions: {
        country: 'ES'  //restrict autocomplete to only spain
      },
    },
  });

  const handleInput = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    console.log(address);
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  const classes = useStyles();


  return (
    <div className="search">


      <Combobox onSelect={handleSelect}>

        <div className={classes.location_input_container}>
          <span><SearchIcon /></span>
          {/* <input type="text" placeholder="¿Dónde?" /> */}
          <ComboboxInput
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder="¿Dónde?"

            style={{ width: 500, }}
          />
        </div>

        <ComboboxPopover className={classes.combobox_popover}>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

function Locate({ panTo }) {
  return (
    <IconButton
      className="locate"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}

      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        background: '#fff',
        width: '40px',
        height: '40px',
        cursor: 'pointer',
        zIndex: '100',
        borderRadius: '10px',
      }}
    >
      <MyLocationIcon />
    </IconButton>
  );
}

export default function Map({
  coordinates,
  radiusKm = 500,
  mapOverlay = false,
  isMainMap = true,
  onRadiusChange,
  onLocationChange
}) {

  const [map, setMap] = React.useState(null)
  const [mapZoom, setMapZoom] = useState(5);
  const [showLocate, setShowLocate] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    console.log('map mounted...');
    const bounds = new window.google.maps.LatLngBounds();
    console.log('bounds ', bounds);
    mapRef.current = map;
    // map.fitBounds(bounds);
    setMap(map);

    setTimeout(() => {
      setShowLocate(true);
    }, 1000);
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    console.log('map unmounted...');
    setMap(null)
  }, [])

  const [openMap, setMapOpen] = useState(false);
  const [circleRadiusKm, setCircleRadiusKm] = useState(500);

  const [mapCenter, setMapCenter] = useState({
    lat: 40.416775,
    lng: -3.703790
  });



  const mapRadiusToZoom = (radiusKm) => {
    // if (key > 7 || key < 1) {
    //   return -1;
    // }
    const _map = {
      1: 13,
      5: 11,
      10: 10,
      30: 9,
      50: 8,
      100: 7,
      200: 6,
      500: 5,
    }

    return _map[radiusKm];
  }

  useEffect(() => {
    if (radiusKm >= 1) {
      console.log('circle radius change.. ', radiusKm);
      setCircleRadiusKm(radiusKm);

      const newZoom = mapRadiusToZoom(radiusKm);
      if (newZoom)
        setMapZoom(newZoom);

    }
  }, [radiusKm]);

  useEffect(() => {
    if (coordinates && coordinates[0] && coordinates[1])
      setMapCenter({
        lat: coordinates[1],
        lng: coordinates[0]
      })
  }, [coordinates])

  const overlayClickHandler = () => {
    console.log('overlay clicked...');
    setMapOpen(true);
  };

  const closeMapClickHandler = () => {
    console.log('closeMap clicked...');
    setMapOpen(false);
  };

  const containerStyle = {
    width: '100%',
    height: openMap ? '400px' : '200px'
  };

  const mapRef = React.useRef();

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    // mapRef.current.setZoom(14);
    setMapZoom(6);
    setMapCenter({ lat, lng });
    setCircleRadiusKm(10);
    setMapZoom(10);
    onRadiusChange(10);
    onLocationChange({ lat: lat, lng: lng });
  }, []);


  return (
    <>

      {
        isMainMap ?
          <Search panTo={panTo} />
          : <></>
      }

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={mapZoom}
        onLoad={onLoad}
        onUnmount={onUnmount}

        onBoundsChanged={(e) => console.log('bounds chnage ', e)}

        options={{
          streetViewControl: false,
          mapTypeControl: false,
          scaleControl: false,
          scrollwheel: false,
          navigationControl: false,
          fullscreenControl: false,
          draggable: isMainMap ? false : true,
          zoomControl: isMainMap || !openMap ? false : true,
        }}
      >
        {
          !isMainMap ? (
            <div style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: 'transparent',
              width: '100%',
              height: '100%',
              zIndex: '100',
              display: openMap ? 'none' : 'block',
            }}
              onClick={overlayClickHandler}
            />
          ) : <></>
        }

        <Circle
          center={mapCenter}

          radius={circleRadiusKm * 1000}
          options={{
            strokeColor: "#333",
            strokeOpacity: .1,
            strokeWeight: 0,
            // fillColor: "#333333",
            fillColor: "#359e0f",
            fillOpacity: 0.2,

          }}
        />

        {
          isMainMap && map ?
            <div style={{ position: "relative" }}>
              {
                showLocate ? <Locate panTo={panTo} /> : <></>
              }
            </div>
            :
            <CloseIcon style={{
              position: 'absolute',
              top: 0,
              right: 0,
              // background: 'black',
              width: '40px',
              height: '40px',
              cursor: 'pointer',
              zIndex: '100',
              display: openMap ? 'block' : 'none',
            }}
              onClick={closeMapClickHandler}
            />
        }

      </GoogleMap>

    </>
  )
}

