import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Ad from '../../components/Ad';
import CreateAds from './CreateAds';

import {
  Popper,
  Fade,
  Card,
  Box,
  CardContent,
  Typography,
  ClickAwayListener,
  Backdrop,
  Grid,
  IconButton,
} from '@material-ui/core';

import {
  makeStyles,
  Container,
  Button,
  CircularProgress,
  Icon,
} from '@material-ui/core';

import { ReactComponent as Todaslascategorias } from '../../assets/icons/Todaslascategorias.svg';
import { ReactComponent as Inmobiliaria } from '../../assets/icons/inmobiliaria.svg';
import { ReactComponent as Motor } from '../../assets/icons/motor.svg';
import { ReactComponent as Moviles } from '../../assets/icons/Moviles.svg';
import { ReactComponent as Informatica } from '../../assets/icons/informatica.svg';
import { ReactComponent as Cine } from '../../assets/icons/cine.svg';
import { ReactComponent as Deporte } from '../../assets/icons/deporte.svg';
import { ReactComponent as Hogar } from '../../assets/icons/Hogar.svg';
import { ReactComponent as Servicios } from '../../assets/icons/servicios.svg';
import { ReactComponent as Empleo } from '../../assets/icons/empleo.svg';

import { ReactComponent as Moda } from '../../assets/icons/moda.svg';
import { ReactComponent as Novedades } from '../../assets/icons/novedades.svg';

import { ReactComponent as MasCaro } from '../../assets/icons/masCaro.svg';
import { ReactComponent as MasBarato } from '../../assets/icons/masBarato.svg';
import { ReactComponent as Euro } from '../../assets/icons/euro.svg';
import { ReactComponent as LocationIcon2 } from '../../assets/icons/location2.svg';
import { ReactComponent as OrdenarPorIcon } from '../../assets/icons/ordenarPor.svg';

import { ReactComponent as PublishIcon } from '../../assets/icons/publish.svg';


import FooterCategories from '../../components/FooterCategories';
import LocationFilter from '../../components/filters/LocationFilter';
import ProductHorizontalAdaptableAdMobile from '../../components/googleAds/ProductHorizontalAdaptableAdMobile';
import ProductHorizontalAdaptableAd from '../../components/googleAds/ProductHorizontalAdaptableAd';
import ProductSideCoverAd from '../../components/googleAds/ProductSideCoverAd';
import ProductSideCoverAdMobile from '../../components/googleAds/ProductSideCoverAdMobile';

const useStyles = makeStyles(theme => ({
  ads: {
    backgroundColor: '#f5f5ff',
    padding: '0 0 2rem',
  },
  ads_container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  progress_bar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '76vh',
  },
  show_more_button: {
    // backgroundColor: '#7300bd',
    background: 'linear-gradient(45deg, #4147b1, #c36bd6)',
    borderRadius: '20px',

    '&:hover': {
      backgroundColor: '#7300bd',
      opacity: .8
    },
    '& span': {
      color: '#fff',
    }
  },

  filters_section: {
    display: 'flex',
    // justifyContent: 'center',
    justifyContent: 'space-around',
    position: 'fixed',
    top: '4.01rem',
    background: '#fff',
    zIndex: '10000',
    maxWidth: '100%',

    [theme.breakpoints.down("xs")]: {
      padding: 0
    },
  },

  category_filters_button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '21px',
    boxShadow: '0 1px 4px 0 rgba(37,50,56,.1)',
    height: '36px',
    padding: '0 12px',
    maxWidth: '290px',
    outline: 'none',
    cursor: 'pointer',
    transition: 'all .15s ease',
    margin: '1rem 1.5rem',
    textTransform: 'capitalize',

    '& > div': {
      paddingRight: '5px',
      paddingTop: '3px',
    },

    '& > div svg': {
      width: '15px',
      height: '15px',
    },

    '& > div svg path': {
      fill: '#737070',
    },

    [theme.breakpoints.down("xs")]: {
      margin: '1rem 0.2rem',
      padding: '0 8px',
      paddingRight: 0,
    },

    '&>span:nth-child(1)': {
      fontSize: '1rem',
      paddingRight: '5px',

      [theme.breakpoints.down("xs")]: {
        fontSize: '12px',
        paddingRight: '0px',
      },
    },

    '&>span:nth-child(2)': {

      [theme.breakpoints.down("xs")]: {
        fontSize: '12px',
        paddingRight: '0px',
      },
    },
    '&>span:nth-child(3)': {
      [theme.breakpoints.down("xs")]: {
        fontSize: '12px',
        paddingRight: '0px',
      },
    },


  },
  selected_category_title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: '12px',
    },
  },
  arrow: {
    border: 'solid #253238',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: '3px',
    transform: 'rotate(45deg)',
    margin: '0 8px',
  },
  filter_button_arrow: {
    transition: 'transform .25s ease,-webkit-transform .25s ease',
    margin: '0 4px 3px',
    [theme.breakpoints.down("xs")]: {
      marginLeft: '0',
    },
  },
  filter_button_arrow_opened: {
    transform: 'rotate(-180deg)',
    marginBottom: '-10px',
  },

  backdrop: {
    zIndex: 120,
    color: '#fff',
    opacity: '.6 !important',
    backgroundColor: '#f4f4f4',
  },

  categories_container: {
    position: 'absolute',
    top: 60,
    width: '40rem',
    overflow: 'hidden',
    padding: '1.2rem .5rem 1.2rem',
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: '0 3px 16px 3px rgba(37,50,56,.2)',
    borderRadius: '10px',

    [theme.breakpoints.down("xs")]: {
      width: '20rem',
      // left: '10%',
      // left: '-30%',
      left: '2%'
    },
  },
  categories_header: {
    fontWeight: '700',
    color: '#222',
    fontSize: '17px',
    margin: '.5rem 1rem',
  },
  category_items_container: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-evenly',
    color: '#959595',
    paddingTop: '1rem',
  },
  category_item: {
    display: 'flex',
    flexDirection: 'column',
    width: '8rem',
    margin: '1rem 1rem 1.5rem',
    textAlign: 'center',

    [theme.breakpoints.down("xs")]: {
      margin: '.5rem .5rem 1rem'
    },

    '&:hover': {
      color: '#c625d4',
      cursor: 'pointer',
    },
    '&:hover path': {
      fill: '#dd72e6',
    },
    '&:hover rect': {
      fill: '#dd72e6',
    }

  },
  category_item_title: {
    fontWeight: '600',
    fontSize: '14px',
    paddingTop: '.5rem',
    textTransform: 'capitalize',
  },
  category_item_icon: {
    '&>svg': {
      '& path': {
        fill: '#959595',
      }
    }
  },

  price_filter_container: {
    width: '25rem',
    position: 'absolute',
    top: 60,
    overflow: 'hidden',
    padding: '1rem 1.5rem',
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: '0 3px 16px 3px rgba(37,50,56,.2)',
    borderRadius: '10px',

    [theme.breakpoints.down("xs")]: {
      width: '18rem',
      right: '-94%',
    },
  },

  price_filter_header: {
    fontWeight: '700',
    color: '#222',
    fontSize: '17px',
    margin: '.5rem 0',
  },
  price_filter_main: {
    display: 'flex',
    overflow: 'hidden',
  },
  price_filter_item: {
    fontSize: '1rem',

    '& p': {
      marginBottom: '5px',
      fontWeight: '600',
    },

    '& input': {
      'border-radius': '6px',
      border: 'thin solid #cfd8dc',
      height: '50px',
      width: '90%',
      padding: '0 7px',
      boxSizing: 'border-box',
    },
    '& input:focus': {
      border: 'thin solid #c625d4',
      outline: 'none',
    }
  },
  price_filter_buttons: {

    margin: '1.5rem 0 .5rem',
    textAlign: 'right',

    '& button': {
      color: '#fff',
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      fontSize: '1rem',
      borderRadius: '2rem',
    },

    '& button:hover': {
      opacity: .7,
    },

    '& button:nth-child(1)': {
      background: 'transparent',
      color: '#253238',
    },

    '& button:nth-child(2)': {
      backgroundColor: '#cd2ed6',
      margin: '0 1rem',
      padding: '10px 32px',
    },
  },

  orderBy_section: {
    position: 'fixed',
    top: '8rem',
    zIndex: '1000',
    background: 'inherit',
    maxWidth: '100%',
    paddingLeft: '4%',
  },

  orderby_filter_container: {
    width: '20rem',
    position: 'absolute',
    top: 60,
    overflow: 'hidden',
    padding: '.8rem 0',
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 2,
    boxShadow: '0 3px 16px 3px rgba(37,50,56,.2)',
    borderRadius: '10px',
  },
  orderby_filter_toggle: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      paddingRight: '2px',
      paddingTop: '3px',
    },

    '& > div svg': {
      width: '14px',
      height: '14px',
    },

    '& > div svg path': {
      fill: '#737070',
    },
  },
  orderby_filter_toggle_text: {
    cursor: 'pointer',

    '&>span:nth-child(1)': {
      fontSize: '12px',
      paddingRight: '3px',
      color: '#959595'
    },
    '&>span:nth-child(2)': {
      fontSize: '13px',
      color: '#6a4ee1',
    },
  },
  orderby_filter_header: {
    fontWeight: '700',
    color: '#222',
    fontSize: '17px',
    margin: '.5rem 0 0',
    paddingBottom: '1.1rem',
    paddingLeft: '1.5rem',
    borderBottom: '1px solid #eceff1',
  },

  orderby_filter_items: {

  },
  orderby_filter_item: {
    borderBottom: '1px solid #eceff1',
    cursor: 'pointer',
    padding: '1px 1.5rem',
    display: 'flex',
    alignItems: 'center',

    '&>span': {
      paddingRight: '1rem',
    },

    '&>p': {
      fontSize: '15px',
      fontWeight: '600',
    },

    '&>span>svg': {
      width: '20px',
      height: '20px',
    },

    '&:hover': {
      backgroundColor: '#f5f5ff',
    }
  },
  orderBy_filter_button: {
    margin: '1rem 1rem 0',
    textAlign: 'right',

    '& button': {
      color: '#fff',
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      fontSize: '1rem',
      borderRadius: '2rem',
    },

    '& button:hover': {
      opacity: .7,
    },

    '& button:nth-child(1)': {
      background: 'transparent',
      color: '#253238',
    },
  },

  ad_banner_container: {

    [theme.breakpoints.down("sm")]: {
      // order: -1,
      // display: 'none'
    },

    '&>div': {
      maxWidth: '300px',
      height: '500px',
      position: 'sticky',
      top: '13rem',
      margin: '8px 0',
      // border: '1px solid',

      [theme.breakpoints.down("xs")]: {
        margin: '8px 0 25px',
        maxWidth: '100%',
        width: '20rem',
        height: '60px',
      },
    },
  },

  publish_ads_button: {

    position: 'fixed',
    width: '100%',
    bottom: '13px',
    left: '0',
    right: 0,
    textAlign: 'center',
    zIndex: '100',

    '& svg': {
      width: '10rem',
      cursor: 'pointer',
    }

    // '& button': {
    //   backgroundColor: '#7300bd',
    //   borderRadius: '20px',

    //   '& span': {
    //     color: '#fff',
    //     textTransform: 'capitalize',
    //   }
    // },

    // '& button:hover': {
    //   backgroundColor: '#7300bd',
    //   opacity: .8
    // }
  }
}));

function Ads({
  goTo,
  getAdsBySearch,
  adsList,
  currentPage,
  searchAdsPending,
  searchParams,
  searchStatus,
  setShowMoreAdsLoading,
  showMoreAdsLoading,
  categories,
  searchTextChange,
  isAuthenticated,
  authDialogToggle,
}) {

  const [openCategories, setCategoriesOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState({
    title: 'Todas las categorías',
    icon: '',
  });
  const [openPriceFilter, setPriceFilterOpen] = React.useState(false);
  const [openOrderByFilter, setOrderByFilterOpen] = React.useState(false);

  const [openBackdrop, setBackdropOpen] = React.useState(false);

  const [innerWidth, setInnerWidth] = useState(1500);
  const [showCreateAd, setShowCreateAd] = useState(false);

  useEffect(() => {
    console.log(window.innerWidth);

    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {

    if (searchParams.lat && searchParams.lng && searchParams.radius)
      getAdsBySearch(searchParams, 1, true);
    else if (searchParams.category) {
      getAdsBySearch(searchParams, 1);
    }

    const category = mapCategoryToIcon(searchParams.category);
    console.log(category);
    if (category) {
      setSelectedCategory({
        title: category.title,
        icon: category.icon
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const category = mapCategoryToIcon(searchParams.category);
    // console.log(category);
    if (category) {
      setSelectedCategory({
        title: category.title,
        icon: category.icon
      });
    }
  }, [categories]);

  useEffect(() => {
    return () => {
      console.log("cleaned up");
      searchTextChange('');
    };
  }, []);



  const mapCategoryToIcon = (categoryTitle) => {
    console.log(categoryTitle);
    if (categoryTitle) {
      const filteredCategory = categories.filter((category) => {
        return category.title === categoryTitle;
      });
      console.log(filteredCategory);
      return filteredCategory[0];
    }
    return null;
  }

  const showMoreClickHandler = () => {
    getAdsBySearch(searchParams, currentPage + 1);
    setShowMoreAdsLoading(true);
  }

  const handleSelectCategory = (category) => {
    goTo(`/search?category=${category}`);

    setCategoriesOpen(false);
    setBackdropOpen(false);
  }

  const handleCategoriesClick = () => {
    setCategoriesOpen((prev) => !prev);
    setBackdropOpen((prev) => !prev);
  };

  const handlePriceFilterClick = () => {
    setPriceFilterOpen((prev) => !prev);
    setBackdropOpen((prev) => !prev);
  };

  const handleOrderByClick = () => {
    setOrderByFilterOpen((prev) => !prev);
    setBackdropOpen((prev) => !prev);
  };

  const handleOrderByCancelClick = () => {
    setOrderByFilterOpen(false);
    setBackdropOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    console.log('click away...');
    setBackdropOpen(false);
    setCategoriesOpen(false);
    setPriceFilterOpen(false);
    setOrderByFilterOpen(false);
    // setOpen(false);
  };


  const mapTextToIcon = (title) => {
    // console.log(title)
    switch (title.toUpperCase()) {

      case "TODAS LAS CATEGORÍAS":
        return <Todaslascategorias />;

      case "INMOBILIARIA":
        return <Inmobiliaria />;

      case "MOTOR":
        return <Motor />;

      case "MOVILES Y TELEFONIA":
        return <Moviles />;

      case "INFORMATICA":
        return <Informatica />;

      case "IMAGEN Y SONIDO":
        return <Cine />;

      case "OCIO/DEPORTE":
        return <Deporte />;

      case "CASA Y JARDIN":
        return <Hogar />;

      case "SERVICIOS":
        return <Servicios />;

      case "NEGOCIO/EMPLEO":
        return <Empleo />;

      case "MODA Y ACCESORIOS":
        return <Moda />;

      default:
        return <Novedades />;
    }
  }

  const classes = useStyles();

  const handleCreateAd = () => {
    if (isAuthenticated) {
      setShowCreateAd(true);
    } else {
      authDialogToggle(true)
    }
  }

  return (
    <>
      <div className={classes.ads}>

        <Backdrop transitionDuration={1} className={classes.backdrop} open={openBackdrop} onClick={handleClickAway}>
        </Backdrop>
        <Container className={classes.filters_section}>
          <div style={{ position: 'relative' }}>

            <div>
              <div className={classes.category_filters_button} onClick={handleCategoriesClick}>
                <div>{mapTextToIcon(selectedCategory.title)}</div>
                <span className={classes.selected_category_title}>{selectedCategory.title}</span>
                <span className={`${classes.filter_button_arrow} ${openCategories ? classes.filter_button_arrow_opened : ''}`}>
                  <span className={classes.arrow}></span>
                </span>
              </div>
              {/* <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleClickAway}>
                </Backdrop> */}

              {openCategories ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.categories_container}>
                    <div className={classes.categories_header}>Elige una categoría en la que buscar</div>
                    <div style={{ margin: 'auto' }}>

                      <div className={classes.category_items_container}>
                        {
                          categories.map((category, i) => {
                            return (
                              <div key={i} className={classes.category_item} onClick={() => handleSelectCategory(category.title)}>
                                <div className={classes.category_item_icon}>
                                  {mapTextToIcon(category.title)}
                                </div>
                                <div className={classes.category_item_title}>
                                  {category.title}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              ) : <></>}
            </div>

          </div>

          {/* <div style={{ position: 'relative' }}>
            <div>
              <div className={classes.category_filters_button} onClick={handlePriceFilterClick}>
                <div><Euro /> </div>
                <span>Precio</span>
                <span className={`${classes.filter_button_arrow} ${openPriceFilter ? classes.filter_button_arrow_opened : ''}`}>
                  <span className={classes.arrow}></span>
                </span>
              </div>

              {openPriceFilter ? (
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.price_filter_container}>
                    <div className={classes.price_filter_header}>¿Cuánto quieres pagar?</div>
                    <div className={classes.price_filter_main}>
                      <div className={classes.price_filter_item}>
                        <p>Desde</p>
                        <input type="number" />
                      </div>
                      <div className={classes.price_filter_item}>
                        <p>Hasta</p>
                        <input type="number" />
                      </div>
                    </div>
                    <div className={classes.price_filter_buttons}>
                      <button>Cancelar</button>
                      <button>Aplicar</button>
                    </div>
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </div> */}

          <LocationFilter filterOpened={(val) => setBackdropOpen(val)} />

        </Container>

        {/* <Container className={classes.orderBy_section} >
          <div style={{
            margin: '1rem 0 0',
            padding: '3px 0 1.1rem',
            borderBottom: '2px solid #eceff1',
          }}>
            <div className={classes.orderby_filter_toggle}>
              <div><OrdenarPorIcon /></div>
              <span onClick={handleOrderByClick} className={classes.orderby_filter_toggle_text}>
                <span>Ordenado por:</span>
                <span>Distancia </span>
              </span>
            </div>

            {openOrderByFilter ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.orderby_filter_container}>
                  <div className={classes.orderby_filter_header}>Ordenado por</div>

                  <div className={classes.orderby_filter_items}>
                    <div className={classes.orderby_filter_item}>
                      <span>
                        <LocationIcon2 />
                      </span>
                      <p>
                        Distancia
                      </p>
                    </div>
                    <div className={classes.orderby_filter_item}>
                      <span>
                        <MasCaro />
                      </span>
                      <p>
                        De más barato a más caro
                      </p>
                    </div>
                    <div className={classes.orderby_filter_item}>
                      <span>
                        <MasBarato />
                      </span>
                      <p>
                        De más caro a más barato
                      </p>
                    </div>
                    <div className={classes.orderby_filter_item}>
                      <span>
                        <Novedades />
                      </span>
                      <p>
                        Novedades
                      </p>
                    </div>
                  </div>
                  <div className={classes.orderBy_filter_button}>
                    <button onClick={handleOrderByCancelClick}>Cancelar</button>
                  </div>
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </Container> */}

        {
          (adsList && adsList.length > 0) || searchAdsPending ?

            <Grid container justify="center" style={{ 'paddingTop': '10rem' }}>
              <Grid item xs={12}>

                <Box
                  display={{ xs: 'none', sm: 'block' }}
                  style={{
                    width: '100%', height: '100px',
                    textAlign: 'center'
                  }}>
                  <ProductHorizontalAdaptableAd />
                </Box>
                <Box
                  display={{ xs: 'block', sm: 'none', md: 'none' }}
                  style={{
                    width: '300px', height: '100px',
                    textAlign: 'center'
                  }}>
                  <ProductHorizontalAdaptableAdMobile />
                </Box>
              </Grid>
              <Grid item sm={10} md={10} lg={9} style={{ width: '100%' }}>
                <div className={classes.ads_container}>
                  {
                    searchAdsPending && !showMoreAdsLoading ? (
                      <div className={classes.progress_bar}>
                        <CircularProgress />
                      </div>
                    )
                      :
                      adsList.map((ad, i) => {
                        return (
                          <Ad key={i} data={ad} />
                        );
                      })}
                </div>
                <div style={{
                  display: searchStatus === 'complete' ? 'none' : 'block',
                  textAlign: 'center',
                }}>
                  <div style={{
                    display: showMoreAdsLoading ? 'block' : 'none',
                    textAlign: 'center',
                    marginBottom: '1.5rem',
                  }}>
                    <CircularProgress />
                  </div>
                  <Button className={classes.show_more_button} variant="contained" onClick={showMoreClickHandler} disabled={showMoreAdsLoading}>
                    Mostrar más resultados
                  </Button>
                </div>
              </Grid>
              <Grid item md={2} className={classes.ad_banner_container}>
                <Box
                  display={{ xs: 'none', sm: 'block' }}
                  style={{ width: '100%', height: '100%' }}>
                  <ProductSideCoverAd />
                </Box>
                <Box
                  display={{ xs: 'block', sm: 'none' }}
                  style={{
                    width: '100%', height: '100px',
                    textAlign: 'center',
                    margin: 0,
                    marginTop: '25px',
                  }}>
                  <ProductSideCoverAdMobile />
                </Box>
              </Grid>
            </Grid>
            :
            <Container>
              <Typography style={{ 'paddingTop': '10rem', margin: '2rem' }} >Actualmente no hay resultados para su búsqueda, ser el primero en publicar gratis tu anuncio</Typography>
            </Container>
        }

        <div className={classes.publish_ads_button}>
          {/* <IconButton onClick={() => window.location = "http://apps.anunciavi.com/"}> */}
          <PublishIcon onClick={handleCreateAd} />
          <CreateAds showCreateAd={showCreateAd} setShowCreateAd={setShowCreateAd} />
          {/* </IconButton> */}
        </div>
      </div>
      <FooterCategories />
    </>
  );
}

const mapStateToProps = state => {
  return {
    'adsList': state.ads.data,
    'currentPage': state.ads.currentPage,
    'searchAdsPending': state.ads.searchAdsPending,
    'getAdsPending': state.ads.getAdsPending,
    'searchStatus': state.ads.searchStatus,
    'showMoreAdsLoading': state.ads.showMoreAdsLoading,
    "categories": state.home.categories,
    "isAuthenticated": state.auth.isAuthenticated,
    // 'searchParams': state.ads.searchParams,
  }
}

export default connect(mapStateToProps, actions)(Ads);