import React, { useEffect, useState } from 'react';

export default function ProductHorizontalAdaptableAd() {
  const [adWidth, setAdWidth] = useState("970px");

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    // < !--Portada Horizontal Adaptable-- >
    <div style={{ display: 'inline-block', width: '100%' }}>
      <ins className="adsbygoogle"
        style={{
          display: "inline-block",
          width: adWidth,
          height: "90px"
        }}

        data-ad-client="ca-pub-9246481794007319"
        data-ad-slot="7209286897"></ins>
      {/* <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-9246481794007319"
        data-ad-slot="7209286897"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins> */}
    </div>
  );
}
