import {
  SEARCH_TEXT_CHANGE,
} from './constants';

const initialState = {
  searchText: '',
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_TEXT_CHANGE:
      return {
        ...state,
        searchText: action.payload
      }

    default: return state;
  }
}

export default headerReducer;