import React, { useEffect, useState } from 'react';

export default function ProductRightAdMobile() {
  const [adWidth, setAdWidth] = useState("300px");
  const [adHeight, setAdHeight] = useState("100px");

  useEffect(() => {

    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    // <!-- Producto 300x600 Derecha Adap -->
    <ins className="adsbygoogle"
      style={{
        display: "inline-block",
        width: adWidth,
        height: adHeight
      }}
      data-ad-client="ca-pub-9246481794007319"
      data-ad-slot="5414641887"></ins>

    // <ins className="adsbygoogle"
    //   style={{ display: "block" }}
    //   data-ad-client="ca-pub-9246481794007319"
    //   data-ad-slot="5414641887"
    //   data-ad-format="auto"
    //   data-full-width-responsive="true"></ins>
  );
}
