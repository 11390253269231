import React from "react";
import actions from "../redux/actions";
import { connect } from "react-redux";
import { makeStyles, Container } from "@material-ui/core";

import { ReactComponent as FacebookIcon } from "../assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter.svg";
import { ReactComponent as Logofooter } from "../assets/icons/Logofooter.svg";
import { ReactComponent as AppleStore } from "../assets/icons/apple_store.svg";

import PlayStoreImg from "../assets/icons/play_store.png";

import { Link, Divider, IconButton } from "@material-ui/core";

function Footer({ categories, goTo, getAdByIdPending }) {
  const useStyles = makeStyles((theme) => ({
    footer_section: {},
    footer_section_container: {
      display: "flex",
      margin: "2rem auto",
      paddingLeft: "3.5rem",
      flexWrap: "wrap",

      [theme.breakpoints.down("xs")]: {
        padding: "0 1rem",
      },
    },
    footer_section_item: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "15rem",
      flexGrow: "1",
      overflow: "hidden",

      [theme.breakpoints.down("xs")]: {
        width: "8rem",
        marginBottom: "1rem",
        marginLeft: "1rem",
        paddingRight: "0",
      },

      "&:nth-child(1)": {
        justifyContent: "center",
        paddingRight: "1rem",
        boxSizing: " border-box",

        [theme.breakpoints.down("xs")]: {
          maxWidth: "100%",
          width: "100%",
          marginBottom: "1rem",
          textAlign: "center",
          paddingRight: "0",
          marginLeft: "0",
        },

        "&>div:nth-child(2)": {
          padding: "8px 0 2px",
          fontSize: "16px",
          color: "#7e7e7e",
        },

        "&>span": {
          color: "#7e7e7e",
          fontSize: "13px",
        },
      },

      "& a": {
        color: "#706f6f",
        fontSize: "14px",
        marginTop: "10px",
      },

      "& a:first-child": {
        color: "#253238",
        fontWeight: "600",
      },

      "& a:hover": {
        cursor: "pointer",
        outline: "none",
        textDecoration: "none",
      },
    },
    footer_section_icons: {
      textAlign: "center",
      letterSpacing: "1px",
      padding: "1rem 0",
      borderTop: "1px solid #cecece",

      margin: "0rem 0 4rem",
      // [theme.breakpoints.down("xs")]: {
      // },
    },
    footer_social_icon: {
      color: "#7300bd",
      width: "40px",
      height: "40px",
    },
    footer_app_store_links_container: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "-60px",

      [theme.breakpoints.down("xs")]: {
        marginTop: "12px",
      },
    },
    footer_app_store_links: {
      width: "auto",
      height: "50px",
      marginRight: "20px",
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.footer_section}>
        <Divider />
        <Container className={classes.footer_section_container}>
          <div className={classes.footer_section_item}>
            <div>
              <Logofooter />
            </div>
            <div>Anunciavi Copyright &#169; 2020</div>
            <span>Anunciavi &#169; de sus respectivos propietarios</span>
          </div>

          <div className={classes.footer_section_item}>
            <Link>Anunciavi</Link>
            <Link
              href="https://web.anunciavi.com/quienes-somos/"
              target="_blank"
            >
              ¿Quiénes somos?
            </Link>
            {/* <Link href="" >Prensa</Link> */}
            <Link href="https://web.anunciavi.com/contactar/" target="_blank">
              Empleo
            </Link>
            <Link href="https://web.anunciavi.com/equipo/" target="_blank">
              Equipo
            </Link>
          </div>
          <div className={classes.footer_section_item}>
            <Link>Soporte</Link>
            <Link
              href="https://web.anunciavi.com/preguntas-frecuentes/"
              target="_blank"
            >
              Preguntas Frecuentes
            </Link>
            <Link
              href="https://web.anunciavi.com/normas-publicacion/"
              target="_blank"
            >
              Reglas de publicación
            </Link>
            {/* <Link href="" target="_blank">Consejos de seguridad</Link> */}
          </div>
          <div className={classes.footer_section_item}>
            <Link>Legal</Link>
            <Link
              href="https://web.anunciavi.com/condiciones-de-uso/"
              target="_blank"
            >
              Condiciones de uso
            </Link>
            <Link
              href="https://web.anunciavi.com/politica-de-privacidad/"
              target="_blank"
            >
              Política de Privacidad
            </Link>
            <Link
              href="https://web.anunciavi.com/politica-cookies/"
              target="_blank"
            >
              Cookies
            </Link>
          </div>
          {/* <div className={classes.footer_section_item}>
            <Link>Motor</Link>
            <Link>Particulares</Link>
            <Link>Profesionales</Link>
          </div> */}
        </Container>
      </div>

      <div className={classes.footer_section_icons}>
        <Link href="https://www.facebook.com/anunciavi" target="_blank">
          <IconButton>
            <FacebookIcon className={classes.footer_social_icon} />
          </IconButton>
        </Link>
        <Link href="https://www.instagram.com/anunciavi/" target="_blank">
          <IconButton>
            <InstagramIcon className={classes.footer_social_icon} />
          </IconButton>
        </Link>
        <Link href="https://twitter.com/anunciavi" target="_blank">
          <IconButton>
            <TwitterIcon className={classes.footer_social_icon} />
          </IconButton>
        </Link>

        <div className={classes.footer_app_store_links_container}>
          <Link
            href="https://play.google.com/store/apps/details?id=com.jumotech.video_anuncios"
            target="_blank"
          >
            <img
              className={classes.footer_app_store_links}
              src={PlayStoreImg}
              alt="play_store"
            />
          </Link>
          <Link
            href="https://apps.apple.com/us/app/id1526982476"
            target="_blank"
          >
            <AppleStore className={classes.footer_app_store_links} />
          </Link>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.home.categories,
    getAdByIdPending: state.ads.getAdByIdPending,
  };
};

export default connect(mapStateToProps, actions)(Footer);
