import React, { useEffect, useState } from 'react';

export default function ProductSideCoverAdMobile() {
  const [adWidth, setAdWidth] = useState("300px");
  const [adHeight, setAdHeight] = useState("100px");

  useEffect(() => {
    try {

      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    // <!-- Portada Lateral 300x600 Adap -->
    <ins className="adsbygoogle"
      style={{
        display: "inline-block",
        width: adWidth,
        height: adHeight,
      }}
      data-ad-client="ca-pub-9246481794007319"
      data-ad-slot="1110782409"></ins>
  );
}
