import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';

import {
  makeStyles, IconButton,
  Button,
} from '@material-ui/core';
import actions from '../redux/actions';
import { connect } from 'react-redux';
import { ReactComponent as PlayIcon } from '../assets/icons/play.svg';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import { ReactComponent as MessageIcon } from '../assets/icons/message.svg';
import { ReactComponent as OnlineIcon } from '../assets/icons/online.svg';
import { ReactComponent as CircleIcon } from '../assets/icons/circle.svg';


const useStyles = makeStyles(theme => ({
  top_section_items_container: {
    [theme.breakpoints.down("xs")]: {
      width: '45%',
    },
  },
  ad_item: {
    width: '12.5rem',
    height: '18rem',
    overflow: 'hidden',
    padding: '6px',
    backgroundColor: '#fff',
    marginBottom: '2rem',
    borderRadius: '32px',
    margin: '.5rem',
    transition: 'all .15s ease',
    boxSizing: 'border-box',

    [theme.breakpoints.down("xs")]: {
      width: '100%',
      margin: 0,
      padding: 0,
      marginBottom: '2rem',
    },

    '&:hover': {
      transform: 'translateY(-5px)',
      cursor: 'pointer',
    },
  },
  ad_item_media: {
    width: '100%',
    height: '10rem',
    backgroundColor: '#ccc',
    borderRadius: '25px',
    position: 'relative',

    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '25px',
    },
  },
  video_icon_container: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: '100',

    '& svg': {
      width: '30px',
      height: '30px',
    }
  },
  ad_item_details: {
    padding: '0 5px 10px 5px',

    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      margin: '5px 0 10px',
      borderBottom: '1px solid #c6cacc',
    },

    '& span': {
      fontSize: '13px',
      color: '#607D8B',
      textTransform: 'lowercase',

      [theme.breakpoints.down("xs")]: {
        fontSize: '13px',
      },
    }
  },
  price: {
    fontWeight: '700',
    fontSize: '1rem',
    padding: '8px 0 2px',
    minWidth: '50px',
    color: '#d37422',
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    padding: '0 0 5px',

    [theme.breakpoints.down("xs")]: {
      fontSize: '.9rem',
    },
  },

  ad_item_bottom_icons: {
    display: 'flex ',
    alignItems: 'center',
    justifyContent: 'space-around',
    // paddingTop: '10px',

    '& .msg_icon ': {
      // borderRight: '1px solid black',
      position: 'relative',

      '& .online_icon, & .circle_icon': {
        position: 'absolute',
        left: '14px',
        top: '1.5px',

        [theme.breakpoints.down("xs")]: {
          top: '2px',
        },

        '& svg': {
          width: '8px',
          height: '8px',
        },
        '& svg circle': {
          fill: '#67E505'
        }
      },

      '& .circle_icon': {
        '& svg circle': {
          fill: '#ea6f07 !important'
        }
      }
    },

    '& svg': {
      width: '22px',
      height: '22px'
    },

    '& .border': {
      height: '30px',
      width: '1px',
      background: '#222',
    },

    '& .msg_icon button svg path': {
      fill: '#6f5ee1'
    },
    '& .msg_icon button': {
      padding: '5px 7px 7px',
    },
    '& .share_icon button ': {
      padding: '5px 7px 7px',
    },
  },
}));

function Ad({
  data,
  goTo,
  getAdById,
}) {

  const [containsVideo, setContainsVideo] = useState(false);
  const [innerWidth, setInnerWidth] = useState(1500);
  const [userOnline, setUserOnline] = useState(false);
  const [userJustOnline, setUserJustOnline] = useState(false);

  useEffect(() => {
    // console.log(window.innerWidth);

    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    firebase.database().ref(`users/${data.userId}`).on('value', (snapshot) => {
      // console.log(snapshot.val());
      if (snapshot.val()) {
        if (snapshot.val().online === true) {
          setUserOnline(true);
        } else {
          setUserAway(snapshot.val())
        }
      }
    });
  }, []);

  // let user away for 15 mins from lastvisit then make him offline
  const setUserAway = (user) => {
    setUserOnline(false);

    const awayTime = new Date(user.lastvisittime).getTime() + (15 * 60 * 1000); // set away time to 15 mins from last visit

    const isUserAway = awayTime > Date.now() ? true : false;

    if (isUserAway) {
      setUserOnline(false);
      setUserJustOnline(true);

      setTimeout(() => {
        setUserJustOnline(false);
        // console.log('away time passed...');
      }, awayTime - Date.now());
    }

  };

  const classes = useStyles();

  const truncateText = (text, len) => {
    // console.log(text.length);
    if (typeof text === "string") {
      return text.length > len ? text.substr(0, len) + "..." : text;
    }
    return "";
  }

  const isVideo = (path) => {
    if (typeof path === "string") {
      const data = path.split("|");
      if (data[0] === "vid") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  const images = data.media.filter((media) => {
    if (isVideo(media.path)) {
      if (!containsVideo) {
        setContainsVideo(true);
      }
      return false;
    }
    return true;
  });

  const adClickHandler = () => {
    window.scrollTo(0, 0);
    goTo(`/ad?id=${data._id}`);
  };

  const shareClickHandler = () => {

    // web share api for mobiles
    if (navigator.share) {
      navigator
        .share({
          title: `Mira que acabo de encontrar en`,
          text: `Mira que acabo de encontrar en `,
          url: `${process.env.REACT_APP_WEB_URL}/ad?id=${data._id}`,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong with sharing the ad', error);
        });
    } else {
      window.scrollTo(0, 0);
      goTo(`/ad?id=${data._id}`, {
        share: true
      });
    }
  };

  return (
    <div className={classes.top_section_items_container} >
      <div className={classes.ad_item}>
        <div className={classes.ad_item_img} onClick={adClickHandler}>
          {/* <img src={data.media[1].url} alt="add img" /> */}

          <div className={classes.ad_item_media}>
            <img src={images[0].url} alt="ad_img" />

            {containsVideo ?
              <div className={classes.video_icon_container}>
                <PlayIcon />
              </div>
              : <></>
            }
          </div>
        </div>
        <div className={classes.ad_item_details} onClick={adClickHandler}>
          <div className="title">
            <div className={classes.title}>{truncateText(data.title, 13)}</div>
            <div className={classes.price}>&#8364; {data.price}</div>
          </div>
          <span>{
            innerWidth < 500 ?
              truncateText(data.description, 22)
              :
              truncateText(data.description, 25)
          }</span>
        </div>

        <div className={classes.ad_item_bottom_icons}>
          <span className="msg_icon">
            {
              userOnline ?
                <div className="online_icon">
                  <OnlineIcon />
                </div>
                :
                <></>
            }
            {
              userJustOnline && !userOnline ?
                <div className="circle_icon">
                  <CircleIcon />
                </div>
                :
                <></>
            }
            <IconButton onClick={() => window.location = "http://apps.anunciavi.com/"}>
              <MessageIcon />
            </IconButton>
          </span>
          <span className="border"></span>
          <span className="share_icon">
            <IconButton onClick={shareClickHandler}>
              <ShareIcon />
            </IconButton>
          </span>
        </div>
            :
            <></>
      </div>
    </div>
  );
}


const mapStateToProps = state => {
  return {

  }
}

export default connect(mapStateToProps, actions)(Ad);
