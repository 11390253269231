import { bindActionCreators } from 'redux';
import {
  push
} from 'connected-react-router';

import * as headerActions from '../containers/Header/actions';
import * as homeActions from '../containers/Home/actions';
import * as adsActions from '../containers/Ads/actions';
import * as authActions from '../containers/Auth/actions';

export default function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    goTo: (path, state = null) => (dispatch) => {
      return dispatch(push(path, state));
    },
    ...authActions,
    ...headerActions,
    ...homeActions,
    ...adsActions,
  }, dispatch);
}