import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Backdrop, makeStyles } from '@material-ui/core';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      // width: '45%',
    },
  },
  backdrop: {
    zIndex: 12000,
    color: '#fff',
    opacity: '.6 !important',
    backgroundColor: 'rgba(23,29,32,.6)',
  },

  cookieConsentBanner: {

    '& > .CookieConsent': {
      background: "#fff",
      zIndex: 12001,
      color: '#000',
    },


    [theme.breakpoints.down("xs")]: {
      // width: '45%',
    },
  },
}));


export default function CookieConsentBanner() {

  const [openBackdrop, setBackdropOpen] = React.useState(false);

  const [cookie, setCookie, removeCookie] = useCookies([]);

  React.useEffect(() => {
    console.log(cookie);
    if (!cookie.CookieConsent) {
      setBackdropOpen(true);
    }
  }, []);


  const acceptClickHandler = () => {
    setBackdropOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Backdrop transitionDuration={1} className={classes.backdrop} open={openBackdrop} />

      <CookieConsent
        disableStyles={true}
        buttonText="Aceptar y cerrar"
        onAccept={acceptClickHandler}
      >

        <h2>Actualización sobre nuestra Política de privacidad</h2>
        <h4>
          Para una experiencia más protegida y personalizada que nunca…
        </h4>
        <p>
          <small>
            Aquí en Anunciavi respetamos su privacidad. Tanto Anunciavi como terceros solo utilizan información no sensible, como identificadores de dispositivo o almacenamiento, para determinar el origen de la visita de nuestra aplicación, evaluar el uso y las preferencias de nuestros usuarios, ofrecer contenido personalizado o categorizar los anuncios que mostramos. . En caso de que quieras cambiar tus preferencias posteriormente puedes hacerlo en cualquier momento desde nuestra aplicación, como se explica en la información detallada sobre protección de datos personales.
          </small>
        </p>

        <p>
          <small>
            Con su consentimiento, nosotros y nuestros socios utilizamos cookies o tecnologías similares para almacenar el acceso y procesar datos personales como su visita a este sitio web. Puede retirar su consentimiento u oponerse al procesamiento de datos basado en un interés legítimo en cualquier momento haciendo clic en "Más información" o en nuestra Política de privacidad en este sitio web.
            Nosotros y nuestros socios realizamos el siguiente procesamiento de datos en función de su consentimiento y / o nuestro interés legítimo:
            Anuncios y contenido personalizados, medición de anuncios y contenido, conocimiento de la audiencia y desarrollo de productos, datos de geolocalización precisos e identificación a través del escaneo del dispositivo, información de almacenamiento y / o acceso en un dispositivo
          </small>
        </p>
      </CookieConsent>
    </div>
  )
}
