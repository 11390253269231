import React from 'react';
import {
  makeStyles,
  Container,
} from '@material-ui/core';
import { connect } from 'react-redux';
import actions from '../redux/actions';

function FeaturedAds({ goTo }) {
  const useStyles = makeStyles(theme => ({
    featured_products: {
      // backgroundColor: '#eceff1',
      backgroundColor: '#f5f5ff',
    },
    top_section: {
      padding: '2rem 2rem',
    },
    section_header: {
      textAlign: 'center',
      marginBottom: '3rem',

      '& h2': {
        fontSize: '2.25rem',
        fontWeight: 'bold',
        letterSpacing: '1px',

        [theme.breakpoints.down("xs")]: {
          fontSize: '1.5rem',
        },
      }
    },
    top_section_items_container: {
      display: 'flex',
      justifyContent: 'space-around',
      [theme.breakpoints.down("xs")]: {
        flexWrap: 'wrap',
      },
    },
    top_section_item: {
      cursor: 'pointer',
      width: '15rem',
      padding: '.5rem',
      backgroundColor: '#fff',

      [theme.breakpoints.down("xs")]: {
        width: '100%',
        marginBottom: '2rem',
      },

      '& img': {
        width: '100%',
        height: '12rem'
      }
    },
    bottom_section: {
      padding: '1rem 2rem 3rem',
    },

    bottom_section_items_container: {
      display: 'flex',
      justifyContent: 'space-around',

      [theme.breakpoints.down("xs")]: {

      },
    },
    bottom_section_item: {
      cursor: 'pointer',

      width: '15rem',
      padding: '.5rem',
      backgroundColor: '#fff',

      '& img': {
        width: '100%',
        height: '12rem'
      }
    },

  }));

  const classes = useStyles();

  return (
    <div className={classes.featured_products}>
      <Container>
        <div className={classes.top_section}>
          <div className={classes.section_header}>
            <h2>Prepara tu salón para el frío</h2>
          </div>
          <div className={classes.top_section_items_container}>
            <div className={classes.top_section_item} onClick={() => goTo('/search')}>
              <img src="/furnitures.jpg" alt="furnitures" />
              <h4>Muebles y decoración</h4>
              <p>40,000 anuncios</p>
            </div>
            <div className={classes.top_section_item}>
              <img src="/furnitures.jpg" alt="furnitures" onClick={() => goTo('/search')} />
              <h4>Muebles y decoración</h4>
              <p>40,000 anuncios</p>
            </div>
            <div className={classes.top_section_item} style={{ margin: 0 }}>
              <img src="/furnitures.jpg" alt="furnitures" onClick={() => goTo('/search')} />
              <h4>Muebles y decoración</h4>
              <p>40,000 anuncios</p>
            </div>
          </div>
        </div>

        <div className={classes.bottom_section}>
          <div className={classes.section_header}>
            <h2>Productos destacados del momento</h2>
          </div>
          <div className={classes.top_section_items_container}>
            <div className={classes.top_section_item}>
              <img src="/furnitures.jpg" alt="furnitures" onClick={() => goTo('/search')} />
              <h4>Muebles y decoración</h4>
              <p>40,000 anuncios</p>
            </div>
            <div className={classes.top_section_item}>
              <img src="/furnitures.jpg" alt="furnitures" onClick={() => goTo('/search')} />
              <h4>Muebles y decoración</h4>
              <p>40,000 anuncios</p>
            </div>
            <div className={classes.top_section_item}>
              <img src="/furnitures.jpg" alt="furnitures" onClick={() => goTo('/search')} />
              <h4>Muebles y decoración</h4>
              <p>40,000 anuncios</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default connect(null, actions)(FeaturedAds);