import React from 'react';
import { connect } from 'react-redux';

import { Route, Redirect } from 'react-router';

function PrivateRoute({ isAuth, component: Component, ...rest }) {
  console.log('isAuth ', isAuth);
  return (

    <Route {...rest} render={(props) => isAuth ?
      (<Component {...props} />)
      : (<Redirect to="/login"></Redirect>)
    } />
  );
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.isAuthenticated
  }
}

export default connect(
  mapStateToProps,
)(PrivateRoute);