import React, { useState, useEffect } from "react";
import {
  makeStyles,
  IconButton,
  Button,
  Grow,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";

import { ArrowDownwardOutlined, ArrowUpwardOutlined } from "@material-ui/icons";

import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

import actions from "../../redux/actions";
import { connect } from "react-redux";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  _paper: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  header_container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    height: "4rem",
    background: "#ffffff",
    borderBottom: "1px solid #ccc",
    position: "fixed",
    top: 0,
    zIndex: 10010,
    maxWidth: "100%",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    margin: "0 0 0 3rem",

    [theme.breakpoints.down("xs")]: {
      // margin: '0 .8rem 0 1rem',
      margin: "0",
    },

    "&>button": {
      width: "64px",
      height: "64px",
    },
  },
  search_input_container: {
    display: "flex",
    width: "60%",
    height: "55%",
    padding: "0rem 0rem",
    alignItems: "center",
    borderRadius: "21px",
    // border: '1px solid #fff',
    // background: '#eceff1',
    backgroundColor: "#f5f5ff",
    boxShadow: "-1px 2px 4px 1px rgb(216 216 232)",
    boxSizing: "border-box",

    [theme.breakpoints.down("xs")]: {
      width: "58%",
    },
  },
  search_input_icon: {
    width: "45px",
    height: "100%",
    color: "#666",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: "21px",
    "& svg": {
      width: "50%",
      height: "50%",
    },
    "& svg>path": {
      fill: "#737070",
    },
  },
  search_input: {
    height: "100%",
    width: "100%",

    "& input": {
      width: "100%",
      height: "100%",
      padding: "0 5px",
      boxSizing: "border-box",
      borderRadius: "17px",
      border: "none",
      outline: "none",
      // background: '#eceff1',
      backgroundColor: "#f5f5ff",
    },
  },
  input_focused: {
    border: "1px solid #624dbb",
  },
  button_container: {
    "& button": {
      cursor: "pointer",
      height: "36px",
      marginLeft: "1.5rem",
      outline: "none",
      padding: "0 12px",
      maxWidth: "290px",
      // boxShadow: "0 1px 4px 0 rgb(37 50 56 / 10%)",
      // transition: "all .15s ease",
      border: "1px solid #7300bd",
      borderRadius: "21px",
      backgroundColor: "#fff",
      color: "#7300bd",
      textTransform: "capitalize",

      [theme.breakpoints.down("xs")]: {
        margin: "0 .3rem",
        padding: "0 5px",
        maxWidth: "80px",
        fontSize: "12px",
        height: "30px",
      },

      "& .MuiButton-endIcon .down-icon": {
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
      },
    },
    "& button:hover": {
      background: "linear-gradient(90deg, #5b38ba, #b665d3)",
      color: "#fff",
    },
  },
  dropdown_button: {
    "& .MuiButton-endIcon": {
      marginLeft: "2px !important",

      "& .up-icon": {
        marginTop: "-4px !important",
      },
      "& .down-icon": {},
    },
  },
}));

function Header(props) {
  const {
    goTo,
    searchText,
    searchTextChange,
    getCategories,
    selectedCategory,
    authDialogToggle,
    isAuthenticated,
    logoutUser,
  } = props;

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const [inputFocus, setInputFocus] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // console.log(e);
      window.scrollTo(0, 0);
      goTo(`/search?category=${e.target.value}`);
    }
  };

  const signUpClickHandler = () => {
    authDialogToggle(true);
  };

  const logoutClickHandler = () => {
    logoutUser();
  };

  const classes = useStyles();

  const anchorRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  return (
    <div className={classes.header_container}>
      <div className={classes.logo}>
        <IconButton style={{ color: "#fff" }} onClick={() => goTo("/")}>
          <Logo />
        </IconButton>
      </div>
      <div
        className={`${classes.search_input_container} ${
          inputFocus ? classes.input_focused : ""
        }`}
      >
        <div className={classes.search_input_icon}>
          <SearchIcon />
        </div>
        <div className={classes.search_input}>
          <input
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            onKeyDown={handleKeyDown}
            name="search_keyword"
            placeholder={`Buscar en ${
              selectedCategory ? selectedCategory : "Todas las categorías"
            }`}
            type="text"
            maxLength="150"
            autoComplete="off"
            value={searchText}
            onChange={(e) => searchTextChange(e.target.value)}
          />
        </div>
      </div>
      <div style={{ display: "flex", paddingRight: "15px" }}>
        <div className={classes.button_container}>
          {isAuthenticated ? (
            <button onClick={logoutClickHandler}>Cerrar sesión</button>
          ) : (
            <button onClick={signUpClickHandler}>Regístrate</button>
          )}
        </div>
        <div className={classes.button_container}>
          <Button
            className={classes.dropdown_button}
            ref={anchorRef}
            color="primary"
            size="small"
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={() => setDropdownOpen(true)}
            endIcon={
              dropdownOpen ? (
                <ArrowUpwardOutlined className="up-icon" />
              ) : (
                <ArrowDownwardOutlined className="down-icon" />
              )
            }
          >
            Descargar
          </Button>
          <Popper
            className={classes.download_popper}
            open={dropdownOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleDropdownClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        // selected={index === selectedIndex}
                        onClick={() =>
                          (window.location =
                            "https://apps.apple.com/us/app/id1526982476")
                        }
                      >
                        Descargar App IOS
                      </MenuItem>
                      <MenuItem
                        // selected={index === selectedIndex}
                        onClick={() =>
                          (window.location =
                            "https://play.google.com/store/apps/details?id=com.jumotech.video_anuncios")
                        }
                      >
                        Descargar App Android
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchText: state.header.searchText,
    selectedCategory: state.ads.searchParams.category,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, actions)(Header);
