import {
  SEARCH_TEXT_CHANGE,
} from './constants';

export const searchTextChange = (searchText) => {
  return async (dispatch) => {

    dispatch({
      type: SEARCH_TEXT_CHANGE,
      payload: searchText,
    })

  }
}