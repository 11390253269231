import React, { useEffect } from 'react'
import {
  Dialog, AppBar, DialogContent, Toolbar, IconButton,
  Grid, Typography, makeStyles, FormControl, FormGroup, TextField,
  CircularProgress, Button,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CreateAdForm from '../../components/CreateAdForm';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import { useState } from 'react';
import axios from 'axios';
import firebase from '../../utils/firebase';
import { toast } from 'react-toastify';
import LocationMap from '../../components/LocationMap';
import { getGeoLocation } from '../../utils/geoLocation';

const useStyles = makeStyles((theme) => ({

  dialog_content: {
    padding: '30px 50px',
    [theme.breakpoints.down("xs")]: {
      padding: '30px 5px 30px 25px'
    }
  }
}));

const getFormattedAddress = (address) => {
  if (typeof address === "string") {
    const addressArr = address.split(',');
    if (addressArr.length > 2) {
      return `${addressArr[addressArr.length - 3] || ''}, ${addressArr[addressArr.length - 2]}, ${addressArr[addressArr.length - 1]}`;
    } else {
      return address;
    }
  }
  return "";
}

function CreateAds({ showCreateAd, setShowCreateAd, userId, createNewAd, createAdPending, adCreated,
  getAdsBySearch, productFiles, setProductFiles }) {

  const classes = useStyles();

  const [showUserLocation, setShowUserLocation] = useState(false);
  const [address, setAddress] = useState('Madrid, Spain');
  const [markerPosition, setMarkerPosition] = useState([-3.703790, 40.416775]);

  const [coordinates, setCoordinates] = useState([-3.703790, 40.416775]);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateAd = async (values) => {
    console.log(values);
    const token = await firebase.auth().currentUser.getIdToken();
    try {
      const user = await axios.get(`https://europe-west1-anunciavi-2019.cloudfunctions.net/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(user);

      if (!user.data.result.address || !user.data.result?.adlocation?.coordinates) {
        setShowUserLocation(true);
        navigator?.geolocation?.getCurrentPosition((position) => {
          console.log(position)
          setMarkerPosition([position.coords.longitude, position.coords.latitude]);
          locationChangeHandler({
            lng: position.coords.longitude,
            lat: position.coords.latitude
          })
        }, () => null);
      } else {
        createNewAd(values);
      }
    } catch (err) {
      console.log(err);
      toast('No se puede publicar un anuncio nuevo.', { type: 'error' })
    }
  }

  const locationChangeHandler = async (location) => {
    // // console.log(location)
    setCoordinates([location.lng, location.lat]);

    const address = await getGeoLocation(location.lat, location.lng);
    // console.log(address);
    setAddress(getFormattedAddress(address));
  }

  const title = {
    fontSize: '1.5rem',
    fontWeight: '700',
    textTransform: 'capitalize',
  };

  const toolbar = {
    display: 'flex',
    justifyContent: 'space-between'
  }

  useEffect(() => {
    setShowCreateAd(false)
    getAdsBySearch({});
  }, [adCreated]);

  const handleUpdateUserLocation = async () => {
    if (!address || !coordinates || !Array.isArray(coordinates)) {
      return;
    }
    setIsLoading(true);

    try {
      const token = await firebase.auth().currentUser.getIdToken();

      // update user address and cooredinates
      const user = await axios.put(`https://europe-west1-anunciavi-2019.cloudfunctions.net/users/${userId}`, {
        address: address,
        adlocation: {
          type: 'Point',
          coordinates: coordinates
        }
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(user);
      setShowUserLocation(false);
    } catch (err) {
      console.log(err);
      toast('No se puede publicar un anuncio nuevo.', { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Dialog
        className="create_ad_dialog"
        open={showCreateAd} onClose={() => setShowCreateAd(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={1200}
        fullScreen
      >
        <AppBar style={{ position: 'relative', background: 'linear-gradient(45deg, rgb(65, 71, 177), rgb(143 63 161))' }}>
          <Toolbar style={toolbar}>
            <Typography variant="h6" style={title}>
              Crear anuncio
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => setShowCreateAd(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={() => setShowCreateAd(false)}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialog_content}>
          <Grid container justify="center">
            <Grid item xs={12} lg={10} xl={8}>
              <CreateAdForm userId={userId} createAd={handleCreateAd} createAdPending={createAdPending} productFiles={productFiles} setProductFiles={setProductFiles} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* user location dialog */}
      <Dialog
        className="create_ad_dialog"
        open={showUserLocation} onClose={() => setShowUserLocation(false)}
        fullWidth={300}
      >
        <AppBar style={{ position: 'relative', background: 'linear-gradient(45deg, rgb(65, 71, 177), rgb(143 63 161))' }}>
          <Toolbar style={toolbar}>
            <Typography variant="h6" style={title}>
              Crear anuncio
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => setShowUserLocation(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={() => setShowCreateAd(false)}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '20px' }}>
          <form>
            <FormGroup>
              <TextField
                name="address"
                placeholder="Habla a"
                label="Habla a"
                variant="standard"
                onChange={(event) => setAddress(event.target.value)}
                value={address}
              />
            </FormGroup>
            <FormGroup style={{ margin: '20px 0' }}>
              <FormControl>
                <LocationMap markerPosition={markerPosition} onLocationChange={locationChangeHandler} />
              </FormControl>
            </FormGroup>
            <FormGroup>
              <FormControl>
                <Button onClick={handleUpdateUserLocation} className='btn-primary' color="primary" variant="contained" disabled={isLoading}>
                  {isLoading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : 'Standort aktualisieren'}
                </Button>
              </FormControl>
            </FormGroup>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

const mapStateToProps = state => {
  return {
    'userId': state.auth.user.id,
    createAdPending: state.ads.createAdPending,
    adCreated: state.ads.adCreated,
    productFiles: state.ads.productFiles,
  }
}

export default connect(mapStateToProps, actions)(CreateAds);