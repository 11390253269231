import {
  GET_CATEGORIES_SUCCESS,
} from './constants';

const initialState = {
  categories: []
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload
      }
    default: return state;
  }
}

export default homeReducer;