import React, { useState } from 'react';
import { Redirect } from "react-router-dom";

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Paper,
} from '@material-ui/core';

import { ReactComponent as Logo } from '../../assets/logo/logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';


import actions from '../../redux/actions';
import { connect } from 'react-redux';
import LogIn from './LogIn';
import SignUp from './SignUp';
import { useHistory } from 'react-router';

function Auth(props) {

  const {
    goTo,
    authDialogOpen,
    authDialogToggle,
    isAuthenticated,
  } = props;

  const useStyles = makeStyles(theme => ({
    'modal_container': {
      width: '500px',
      padding: '20px 0',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginLeft: 10,
        marginRight: 10,
      }
    },
    paper: {
      display: "flex",
      padding: '30px 20px',
      flexDirection: "column",
      alignItems: "center",

      [theme.breakpoints.down('xs')]: {
        padding: '0px 20px 30px',
      },
    },
    'auth_modal': {
      zIndex: "13000 !important",
    },
    'dialog_title': {
      textAlign: "right",
      padding: '0 5px',
    },
    modal_dialog_content: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
    }
  }));

  const [showSignup, setShowSignup] = useState(false);

  const handleDialogClose = () => {
    authDialogToggle(false);
  }

  const classes = useStyles();

  if (isAuthenticated) {
    authDialogToggle(false);
    return <Redirect to="/" />;
  } else {

    return (
      <div className={classes.modal_container}>
        <Dialog className={classes.auth_modal} open={authDialogOpen} onClose={handleDialogClose} aria-labelledby="customized-dialog-title">
          <DialogTitle className={classes.dialog_title} id="customized-dialog-title">
            <IconButton onClick={handleDialogClose}>X</IconButton>
          </DialogTitle>
          <DialogContent className={classes.modal_dialog_content}>
            <div className={classes.auth_container}>
              <Paper className={classes.paper}>
                {showSignup ? <SignUp setShowSignup={setShowSignup} /> : <LogIn setShowSignup={setShowSignup} />}
              </Paper>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    'authDialogOpen': state.auth.authDialogOpen,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps, actions)(Auth);
