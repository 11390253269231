import React, { useEffect } from 'react';
import {
  makeStyles,
  Container
} from '@material-ui/core';
import CategorySlider from '../../components/CategorySlider';
import FeaturedAds from '../../components/FeaturedAds';

import actions from '../../redux/actions';
import { connect } from 'react-redux';

function Home({
  getCategories,
  categories,
  location,
  goTo,
}) {

  // useEffect(() => {

  //   getCategories();
  // }, [getCategories]);

  // console.log(location);

  const useStyles = makeStyles(theme => ({

    home_category_container: {
      'margin-top': '6rem',
    },
    home_category_text: {
      color: '#607d8b',
      fontWeight: 300,
      fontSize: '1rem',
      margin: '1rem 2rem',
      textAlign: 'center',
    },
    home_category_header: {
      fontSize: '2.25rem',
      textAlign: 'center',
      fontWeight: 'bold',
      margin: '1.8rem 0',
      letterSpacing: '1px',

      [theme.breakpoints.down("xs")]: {
        fontSize: '1.5rem',
      },
    },
    home_categories: {

    },
    footer_categories_container: {
      display: 'flex',
      // justifyContent: 'center',
      flexWrap: 'wrap',
      marginLeft: '2rem',
      padding: '2rem 0',
    },
    footer_categories: {
      minWidth: '12rem',
    },
    category_title: {
      margin: '10px 0',
      fontWeight: '700',
      fontSize: '15px',
      color: '#607D8B',

      '&:hover': {
        cursor: 'pointer',
        color: '#6a4ee1',
      }
    },
    footer_subcategories: {
      color: '#90A4AE',
      fontSize: '14px',
      fontWeight: '600',

      '&:hover': {
        cursor: 'pointer',
        color: '#6a4ee1',
      }
    },
  }));

  const classes = useStyles();

  const categoryClickHandler = (category) => {
    goTo(`/search?category=${category.title}`, {
      category: category.title
    });
  }

  // const footerCategoryClickHandler = (category) => {
  //   const isSearch = window.location.pathname.indexOf('search') > -1;
  //   console.log(isSearch);

  //   window.scrollTo({
  //     top: 0,
  //     behavior: isSearch ? 'smooth' : 'auto',
  //   });

  //   goTo(`/search?category=${category}`);

  // }


  return (
    <div>
      <div className={classes.home_category_container}>
        <div className={classes.home_category_text}>
          la plataforma líder de compraventa de productos de Segunda mano
        </div>
        <div className={classes.home_category_header}>
          ¿Qué estás buscando hoy?
        </div>
        <div className={classes.home_categories}>
          <CategorySlider categories={categories} onCategoryClick={categoryClickHandler} />
        </div>
      </div>

      <FeaturedAds />

    </div>
  )
}

const mapStateToProps = state => {
  return {
    "categories": state.home.categories
  }
}

export default connect(mapStateToProps, actions)(Home);