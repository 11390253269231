import Geocode from "react-geocode";

export async function getGeoLocation(lat, lng) {

  try {
    console.log('getGeoLocation called...');

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    Geocode.enableDebug();

    const response = await Geocode.fromLatLng(lat, lng);
    const address = response.results[0].formatted_address;
    console.log(address);

    return address;

  } catch (err) {
    console.log('GetGeoLocation err: ', err);
  }
}
