import React, { useState } from 'react';
import {
  makeStyles, Container,
} from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { ReactComponent as Todaslascategorias } from '../assets/icons/Todaslascategorias.svg';
import { ReactComponent as Inmobiliaria } from '../assets/icons/inmobiliaria.svg';
import { ReactComponent as Motor } from '../assets/icons/motor.svg';
import { ReactComponent as Moviles } from '../assets/icons/Moviles.svg';
import { ReactComponent as Informatica } from '../assets/icons/informatica.svg';
import { ReactComponent as Cine } from '../assets/icons/cine.svg';
import { ReactComponent as Deporte } from '../assets/icons/deporte.svg';
import { ReactComponent as Hogar } from '../assets/icons/Hogar.svg';
import { ReactComponent as Servicios } from '../assets/icons/servicios.svg';
import { ReactComponent as Empleo } from '../assets/icons/empleo.svg';

import { ReactComponent as Moda } from '../assets/icons/moda.svg';
import { ReactComponent as Novedades } from '../assets/icons/novedades.svg';

export default function CategorySlider({
  categories,
  onCategoryClick,
}) {

  const useStyles = makeStyles(theme => ({
    carousel_container: {
      height: '15rem',
    },
    carousel_item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10rem',
      cursor: 'pointer',
      textAlign: 'center',

      '&:hover div:nth-child(2)': {
        color: '#dd72e6 ',
        cursor: 'pointer',
      },
      '&:hover path': {
        fill: '#dd72e6',
      },
      '&:hover rect': {
        fill: '#dd72e6',
      }
    },
    carousel_item_hovered: {
      color: '#fff',
      cursor: 'pointer'
    },
    carousel_item_icon_container: {
      // width: '68px',
      // height: '68px',
    },
    carousel_item_icon: {
      // width: '3.5rem',
      // height: '3.5rem',
      fontSize: '3rem',
      color: '#4d5e6f',

      '&>svg': {
        width: '40px',
        height: '40px',

        '& path': {
          fill: '#959595',
        }
      }
    },
    carousel_item_text: {
      fontSize: '1rem',
      marginTop: '1rem',
      color: '#90A4AE',
      textTransform: 'capitalize'
    },
  }));

  const mapTextToIcon = (title) => {
    switch (title.toUpperCase()) {

      case "TODAS LAS CATEGORÍAS":
        return <Todaslascategorias />;

      case "INMOBILIARIA":
        return <Inmobiliaria />;

      case "MOTOR":
        return <Motor />;

      case "MOVILES Y TELEFONIA":
        return <Moviles />;

      case "INFORMATICA":
        return <Informatica />;

      case "IMAGEN Y SONIDO":
        return <Cine />;

      case "OCIO/DEPORTE":
        return <Deporte />;

      case "CASA Y JARDIN":
        return <Hogar />;

      case "SERVICIOS":
        return <Servicios />;

      case "NEGOCIO/EMPLEO":
        return <Empleo />;

      case "MODA Y ACCESORIOS":
        return <Moda />;

      default:
        return <Novedades />;
    }
  }

  const classes = useStyles();

  const [itemFocus, setItemFocus] = useState(false);

  return (
    <div className={classes.carousel_container}>
      <Container>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 6,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 2,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 5,
              partialVisibilityGutter: 30
            }
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {
            categories.map((category, i) => {
              return (
                <div
                  key={i}
                  className={classes.carousel_item}
                  onFocus={() => setItemFocus(true)}
                  onBlur={() => setItemFocus(false)}
                  onClick={() => onCategoryClick(category)}
                >
                  <div className={classes.carousel_item_icon_container}>
                    <span className={classes.carousel_item_icon}>{mapTextToIcon(category.title)}</span>
                  </div>
                  <div className={classes.carousel_item_text}>
                    {category.title.toLowerCase()}
                  </div>
                </div>
              );
            })
          }
        </Carousel>
      </Container>
    </div>
  )
}
