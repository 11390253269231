import { toast } from "react-toastify";
import axios from "axios";
import {
  AUTH_DIALOG_OPEN,
  AUTH_DIALOG_CLOSE,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "./constants";

import firebase from "../../utils/firebase";
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
facebookProvider.addScope("email");

export const authDialogToggle = (open = false) => {
  return async (dispatch) => {
    if (open === true) {
      dispatch({
        type: AUTH_DIALOG_OPEN,
      });
    } else {
      dispatch({
        type: AUTH_DIALOG_CLOSE,
      });
    }
  };
};

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (data) => {
  return {
    type: LOGIN_SUCCESS,
    user: data.user,
    token: data.token,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestSignup = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};

const receiveSignup = (data) => {
  return {
    type: SIGNUP_SUCCESS,
  };
};

const signupError = (payload) => {
  return {
    type: SIGNUP_FAILURE,
    payload,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const signupUser = (signupUser) => async (dispatch) => {
  dispatch(requestSignup());
  try {
    // console.log(signupUser);

    if (
      !signupUser.firstName ||
      !signupUser.lastName ||
      !signupUser.email ||
      !signupUser.password
    ) {
      return dispatch(signupError("All fields are required."));
    }

    const { email, password } = signupUser;

    const user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    await user.user.updateProfile({
      displayName: `${signupUser.firstName} ${signupUser.lastName}`,
    });

    const currentUser = firebase.auth().currentUser;
    console.log(currentUser);
    await currentUser.sendEmailVerification();

    dispatch(authDialogToggle(false));

    // dispatch new suucess alert for email verification
    toast(
      "El correo electrónico de verificación le envió su dirección de correo electrónico.",
      { type: "success" }
    );

    await saveNewUser();

    await firebase.auth().signOut(); // only login when email is verified
    dispatch(receiveSignup());
  } catch (error) {
    console.log(error.message);
    dispatch(signupError(error.message));
  }
};

export const loginUser = (email, password) => async (dispatch) => {
  dispatch(requestLogin());
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    const currentUser = firebase.auth().currentUser;

    console.log(currentUser);

    if (!currentUser.emailVerified) {
      await currentUser.sendEmailVerification();
      // send email not verified message
      toast(
        "Se envió el correo electrónico de verificación. Verifique su correo electrónico antes de iniciar sesión.",
        { type: "error" }
      );
      await firebase.auth().signOut();
      dispatch(authDialogToggle(false));
      dispatch(receiveSignup());
      return;
    }

    const user = {
      id: currentUser.uid,
      name: currentUser.displayName,
      email: currentUser.email,
      emailVerified: currentUser.emailVerified,
      photoUrl: currentUser.photoURL,
    };
    const token = await firebase.auth().currentUser.getIdToken();

    dispatch(receiveLogin({ user, token }));
  } catch (error) {
    console.log(error);
    dispatch(loginError());
  }
};

export const loginWithGoogleUser = () => (dispatch) => {
  dispatch(requestLogin());
  firebase
    .auth()
    .signInWithPopup(googleProvider)
    .then((result) => {
      console.log(result);
      const token = result.credential.accessToken;
      const user = {
        id: result.user.uid,
        name: result.user.displayName,
        email: result.user.email,
        emailVerified: result.user.emailVerified,
        photoUrl: result.user.photoURL,
      };

      if (result.additionalUserInfo.isNewUser) {
        console.log("new user");
        saveNewUser();
      }

      dispatch(receiveLogin({ user, token }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(loginError());
    });
};

export const loginWithFacebook = () => (dispatch) => {
  dispatch(requestLogin());
  firebase
    .auth()
    .signInWithPopup(facebookProvider)
    .then((result) => {
      console.log(result);
      const token = result.credential.accessToken;
      const user = {
        id: result.user.uid,
        name: result.user.displayName,
        email: result.user.email,
        emailVerified: result.user.emailVerified,
        photoUrl: result.user.photoURL,
      };

      if (result.additionalUserInfo.isNewUser) {
        console.log("new user");
        saveNewUser();
      }

      dispatch(receiveLogin({ user, token }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(loginError());
    });
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => (dispatch) => {
  dispatch(verifyRequest());
  firebase.auth().onAuthStateChanged((user) => {
    if (user !== null) {
      dispatch(receiveLogin(user));
    }
    dispatch(verifySuccess());
  });
};

const saveNewUser = async () => {
  const currentUser = firebase.auth().currentUser;
  const user = {
    uid: currentUser.uid,
    name: currentUser.displayName,
    email: currentUser.email,
    picture: currentUser.photoURL,
  };

  const token = await firebase.auth().currentUser.getIdToken();

  const response = await axios.post(
    "https://europe-west1-anunciavi-2019.cloudfunctions.net/users",
    user,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response);
  return response;
};
