import {
  AUTH_DIALOG_OPEN,
  AUTH_DIALOG_CLOSE,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from './constants';

const initialState = {
  authDialogOpen: false,
  isSigningUp: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  signupError: '',
  loginError: false,
  logoutError: false,
  isAuthenticated: false,
  user: {},
  accessToken: null,
};

const authReducer = (state = initialState, action) => {

  switch (action.type) {
    case AUTH_DIALOG_OPEN:
      return {
        ...state,
        authDialogOpen: true
      }

    case AUTH_DIALOG_CLOSE:
      return {
        ...state,
        authDialogOpen: false
      }
    case SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        signupError: false
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        isAuthenticated: false, // isAuth will be true when email is verified
        signupError: false,
        loginError: false,
        isLoggingIn: false,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        isAuthenticated: false,
        signupError: action.payload
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
        accessToken: action.token,
        signupError: false,
        loginError: false
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        accessToken: null,
        user: {}
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false
      };

    default: return state;
  }
}

export default authReducer;
