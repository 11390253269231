import axios from 'axios';
import {
  GET_CATEGORIES_PENDING,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILED,
} from './constants';
// import { ReactComponent as Logo } from '../../assets/logo/logo.svg';

import firebase from '../../utils/firebase';

export const getCategories = () => {
  return async (dispatch) => {

    const db = firebase.firestore();
    const categoriesDoc = await db.doc('configuration/categories').get();

    const categories = await categoriesDoc.data();
    // console.log(categories);
    let categoriesWithIcon = categories.data.map((el) => {

      switch (el.main) {
        case "INMOBILIARIA":
          return {
            title: el.main.toLowerCase(),
            icon: "inmobiliaria.svg",
            sub: el.sub,
          };

        case "MOTOR":
          return {
            title: el.main.toLowerCase(),
            icon: "motor.svg",
            sub: el.sub,
          };

        case "MOVILES Y TELEFONIA":
          return {
            title: el.main.toLowerCase(),
            icon: "Moviles.svg",
            sub: el.sub,
          };

        case "INFORMATICA":
          return {
            title: el.main.toLowerCase(),
            icon: "informatica.svg",
            sub: el.sub,
          };

        case "IMAGEN Y SONIDO":
          return {
            title: el.main.toLowerCase(),
            icon: "cine.svg",
            sub: el.sub,
          };

        case "OCIO/DEPORTE":
          return {
            title: el.main.toLowerCase(),
            icon: "deporte.svg",
            sub: el.sub,
          };

        case "CASA Y JARDIN":
          return {
            title: el.main.toLowerCase(),
            icon: "Hogar.svg",
            sub: el.sub,
          };

        case "SERVICIOS":
          return {
            title: el.main.toLowerCase(),
            icon: "servicios.svg",
            sub: el.sub,
          };

        case "NEGOCIO/EMPLEO":
          return {
            title: el.main.toLowerCase(),
            icon: "empleo.svg",
            sub: el.sub,
          };

        case "MODA Y ACCESORIOS":
          return {
            title: el.main.toLowerCase(),
            icon: "moda.svg",
            sub: el.sub,
          };

        default:
          return {
            title: el.main.toLowerCase(),
            icon: "novedades.svg",
            sub: el.sub,
          };
      }

    });

    categoriesWithIcon = [{
      title: "Todas las categorías",
      icon: "Todaslascategorias.svg"
    },
    ...categoriesWithIcon
    ];

    // console.log(categoriesWithIcon);

    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: categoriesWithIcon
    })

  }
}