import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import homeReducer from '../containers/Home/reducers';
import headerReducer from '../containers/Header/reducers';
import adsReducer from '../containers/Ads/reducers';
import authReducer from '../containers/Auth/reducers';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth:authReducer,
  home: homeReducer,
  header: headerReducer,
  ads: adsReducer,
});

export default rootReducer;