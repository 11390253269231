import React, { useEffect, useState } from 'react';

export default function ProductHeaderAd({ currentPath }) {
  const [adWidth, setAdWidth] = useState("970px");

  useEffect(() => {
    //     (adsbygoogle=window.adsbygoogle||[]).requestNonPersonalizedAds=1;
    // (adsbygoogle=window.adsbygoogle||[]).pauseAdRequests=0;

    try {

      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    // {/* // <!-- Producto Header Adap --> */}
    <ins className="adsbygoogle"
      style={{
        display: "inline-block",
        width: adWidth,
        height: "90px"
      }}
      data-ad-client="ca-pub-9246481794007319"
      data-ad-slot="3078470196"></ins>

  );
}
