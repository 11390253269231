import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import { persistStore, persistReducer, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// redux-persist is used to persist redux state on page refresh 
// whitelist the reducers which you want to persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const middlewares = composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)));

const store = createStore(persistedReducer, middlewares);

export const persistor = persistStore(store);

export default store;