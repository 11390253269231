import axios from 'axios';
import { toast } from 'react-toastify';
import {
  CREATE_AD_PENDING,
  CREATE_AD_SUCCESS,
  CREATE_AD_FAILED,
  GET_ADS_PENDING,
  GET_ADS_SUCCESS,
  GET_ADS_FAILED,
  SEARCH_ADS_PENDING,
  SEARCH_ADS_SUCCESS,
  SEARCH_ADS_FAILED,
  SHOW_MORE_ADS_LOADING,
  CLEAR_ADS_DATA,
  GET_AD_BY_ID_PENDING,
  GET_AD_BY_ID_SUCCESS,
  GET_AD_BY_ID_FAILED,
  SET_PRODUCT_FILES,
  GET_USER_PRODUCTS_SUCCESS,
} from './constants';
import firebase from '../../utils/firebase';

export const setProductFiles = (files) => dispatch => {
  dispatch({
    type: SET_PRODUCT_FILES,
    payload: files,
  })
}

export const createNewAd = (data) => async (dispatch) => {
  try {
    const token = await firebase.auth().currentUser.getIdToken();
    const userId = await firebase.auth().currentUser.uid;

    if (!token) {
      throw new Error("No id token");
    }

    if (!data.media || data.media.length <= 0) {
      toast('Proporcione al menos una imagen o un video', { type: 'error' })
      return;
    }

    dispatch({
      type: CREATE_AD_PENDING
    });

    const media = data.media?.map((media, i) => i === 0 ? { ...media, isMain: true, } : { ...media, isMain: false })

    // create new ad
    let reqUrl = `https://europe-west1-anunciavi-2019.cloudfunctions.net/product`;
    // let reqUrl = 'http://localhost:5000/anunciavi-2019/europe-west1/product';

    const response = await axios.post(`${reqUrl}`, {
      title: data.title,
      description: data.description,
      price: data.price,
      categories: [data.categories],
      subcategories: [data.subcategories],
      userId: userId,
      media: media,
      phoneNumber: null,
    },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

    console.log(response);

    toast('Anuncio publicado con éxito.', { type: 'success' })
    dispatch({
      type: CREATE_AD_SUCCESS,
    });

  } catch (err) {
    console.log(err);
    toast('No se puede publicar un anuncio nuevo.', { type: 'error' })

    dispatch({
      type: CREATE_AD_FAILED,
      payload: err?.message
    });
  }
}

export const getAdsBySearch = (searchParams, page = 1, clearPrevious = false) => {
  return async (dispatch, getState) => {

    try {

      console.log('new getAdsBySearch request...')

      const adsState = getState().ads;
      const perPage = adsState.perPage;
      const currentPage = adsState.currentPage;
      // const isSearchStatusComplete = adsState.searchStatus === 'complete' ? true : false;

      // if (isSearchStatusComplete || (adsState.data.length > 0 && currentPage >= page)) {
      //   console.log("do not load ads...");
      //   return;
      // }

      dispatch({
        type: SEARCH_ADS_PENDING
      });

      if (clearPrevious) {
        dispatch({
          type: CLEAR_ADS_DATA
        })
      }

      // encode category names because category name contains some reserved characters such as '/'
      const category = encodeURIComponent(searchParams.category);

      let reqUrl = `https://europe-west1-anunciavi-2019.cloudfunctions.net/products`;
      // let reqUrl = `http://localhost:5000/anunciavi-2019/europe-west1/ads`;

      if (searchParams.category && searchParams.lat && searchParams.lng && searchParams.radius) {
        reqUrl += `/text/${searchParams.lat}/${searchParams.lng}/${searchParams.radius}/${page}/${perPage}`;


      } else if (searchParams.category) {
        if (searchParams.category.toLowerCase() === 'todas las categorías') {
          reqUrl += `/${page}/${perPage}`;

        } else {
          reqUrl += `/text/${category}/${page}/${perPage}`;
        }
      } else if (searchParams.lat && searchParams.lng && searchParams.radius) {
        reqUrl += `/text/${searchParams.lat}/${searchParams.lng}/${searchParams.radius}/${page}/${perPage}`;

      } else {
        reqUrl += `/${page}/${perPage}`;
      }

      const response = await axios.get(`${reqUrl}`);

      // console.log(response);

      const totalResults = response.data.totalResults;
      let searchStatus = 'incomplete';

      const searchLimitReached = totalResults < page * perPage ? true : false;

      if (response.data.result.length <= 0 || searchLimitReached) {
        searchStatus = 'complete'
      }

      let responseData = [];

      if (Array.isArray(response.data.result) && response.data.result.length > 0) {
        responseData = response.data.result;
      } else if (response.data.result.ads && response.data.result.ads.length > 0) {
        responseData = response.data.result.ads;
      }

      // replace ads data if initial page else append to previous data
      const adsData = page === 1 ? responseData : [...adsState.data, ...responseData];

      dispatch({
        type: SEARCH_ADS_SUCCESS,
        payload: {
          data: adsData,
          page: page,
          total: totalResults,
          status: searchStatus,
          searchParams: searchParams,
        }
      });

    } catch (err) {
      console.log(err.response);
      dispatch({
        type: SEARCH_ADS_FAILED,
        payload: 'Error'
      });
      console.log(err);
    }
  }
}

export const getAds = (page) => {
  return async (dispatch, getState) => {

    try {

      const adsState = getState().ads;
      const perPage = adsState.perPage;
      const currentPage = adsState.currentPage;
      const isSearchStatusComplete = adsState.searchStatus === 'complete' ? true : false;

      if (isSearchStatusComplete || (adsState.data.length > 0 && currentPage >= page)) {
        console.log("do not load ads...");
        return;
      }

      dispatch({
        type: GET_ADS_PENDING
      });

      const response = await axios.get(`https://europe-west1-anunciavi-2019.cloudfunctions.net/products/${page}/${perPage}`);
      // const response = await axios.get(`http://localhost:5000/anunciavi-2019/europe-west1/ads/${page}/${perPage}`);

      let reqUrl = ``;


      console.log(response);

      const totalResults = response.data.totalResults;
      let searchStatus = 'incomplete';

      const searchLimitReached = totalResults < currentPage * perPage ? true : false;

      if (response.data.result.length <= 0 || searchLimitReached) {
        searchStatus = 'complete'
      }

      let responseData = [];

      if (Array.isArray(response.data.result) && response.data.result.length > 0) {
        responseData = response.data.result;
      } else if (response.data.result.ads && response.data.result.ads.length > 0) {
        responseData = response.data.result.ads;
      }

      console.log(responseData);
      dispatch({
        type: GET_ADS_SUCCESS,
        payload: {
          data: responseData,
          page: page,
          total: totalResults,
          status: searchStatus,
        }
      });

    } catch (err) {
      console.log(err.response);
      dispatch({
        type: GET_ADS_FAILED,
        payload: 'Error'
      });
    }
  }
}


export const getAdById = (adId) => {
  return async (dispatch, getState) => {

    try {


      dispatch({
        type: GET_AD_BY_ID_PENDING
      });

      // const response = await axios.get(`http://localhost:5000/anunciavi-2019/europe-west1/adById/${adId}`);

      const response = await axios.get(`https://europe-west1-anunciavi-2019.cloudfunctions.net/productById/${adId}`);

      console.log(response);

      dispatch({
        type: GET_AD_BY_ID_SUCCESS,
        payload: response.data.result.data,
      });

    } catch (err) {
      console.log(err.response);
      dispatch({
        type: GET_AD_BY_ID_FAILED,
        payload: 'Error'
      });
    }
  }
}

export const getUserProducts = (userId) => {
  return async (dispatch, getState) => {
    console.log('getUserProducts')
    try {
      const response = await axios.get(`https://europe-west1-anunciavi-2019.cloudfunctions.net/products/user/${userId}`);

      console.log(response);

      dispatch({
        type: GET_USER_PRODUCTS_SUCCESS,
        payload: response.data.totalResults,
      });

    } catch (err) {
      console.log(err.response);
      dispatch({
        type: GET_AD_BY_ID_FAILED,
        payload: 'Error'
      });
    }
  }
}

export const setShowMoreAdsLoading = (isLoading) => {
  return async (dispatch) => {
    dispatch({
      type: SHOW_MORE_ADS_LOADING,
      payload: isLoading
    })
  }
}
