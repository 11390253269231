import {
  CREATE_AD_PENDING,
  CREATE_AD_SUCCESS,
  CREATE_AD_FAILED,
  GET_ADS_PENDING,
  GET_ADS_SUCCESS,
  GET_ADS_FAILED,
  SEARCH_ADS_PENDING,
  SEARCH_ADS_SUCCESS,
  SEARCH_ADS_FAILED,
  SHOW_MORE_ADS_LOADING,
  CLEAR_ADS_DATA,
  GET_AD_BY_ID_PENDING,
  GET_AD_BY_ID_SUCCESS,
  GET_AD_BY_ID_FAILED,
  SET_PRODUCT_FILES,
  GET_USER_PRODUCTS_SUCCESS,
} from './constants';

const initialState = {
  data: [],
  searchAdsPending: false,
  searchAdsErrorMsg: '',
  createAdPending: false,
  createAdErrorMsg: '',
  adCreated: false,
  getAdsPending: false,
  getAdsErrorMsg: '',
  totalResults: null,
  currentPage: null,
  perPage: 20,
  searchStatus: 'incomplete',
  searchParams: {},
  showMoreAdsLoading: false,
  productFiles: [],
  adData: null,
  getAdByIdPending: false,
  userProductsCount: 'NA',
};

const adsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PRODUCT_FILES:
      return {
        ...state,
        productFiles: action.payload,
      }
    case CREATE_AD_PENDING:
      return {
        ...state,
        createAdPending: true,
        adCreated: false,
      }

    case CREATE_AD_SUCCESS:
      return {
        ...state,
        createAdPending: false,
        adCreated: true,
      }

    case CREATE_AD_FAILED:
      return {
        ...state,
        createAdPending: false,
        createAdErrorMsg: action.payload
      }

    case SEARCH_ADS_PENDING:
      return {
        ...state,
        searchAdsPending: true,
        userProductsCount: 'NA',
      }

    case SEARCH_ADS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        totalResults: action.payload.total,
        currentPage: action.payload.page,
        searchStatus: action.payload.status,
        searchParams: action.payload.searchParams,
        searchAdsPending: false,
        showMoreAdsLoading: false,
      }

    case SEARCH_ADS_FAILED:
      return {
        ...state,
        searchAdsErrorMsg: action.payload,
        searchAdsPending: false,
        showMoreAdsLoading: false,
      }

    case GET_USER_PRODUCTS_SUCCESS:
      return {
        ...state,
        userProductsCount: action.payload,
      }

    case CLEAR_ADS_DATA:
      return {
        ...state,
        data: []
      }

    case SHOW_MORE_ADS_LOADING:
      return {
        ...state,
        showMoreAdsLoading: action.payload,
      }

    case GET_ADS_PENDING:
      return {
        ...state,
        getAdsPending: true,
        userProductsCount: 'NA',
      }

    case GET_ADS_SUCCESS:
      return {
        ...state,
        data: [
          ...state.data,
          ...action.payload.data
        ],
        totalResults: action.payload.total,
        currentPage: action.payload.page,
        getAdsPending: false,
        searchStatus: action.payload.status,
        searchParams: action.payload.searchParams,
      }

    case GET_ADS_FAILED:
      return {
        ...state,
        getAdsPending: false,
        getAdsErrorMsg: action.payload
      }

    case GET_AD_BY_ID_PENDING:
      return {
        ...state,
        getAdByIdPending: true,
        userProductsCount: 'NA',
      }

    case GET_AD_BY_ID_SUCCESS:
      return {
        ...state,
        adData: action.payload,
        getAdByIdPending: false,
      }

    case GET_AD_BY_ID_FAILED:
      return {
        ...state,
        getAdByIdPending: false
      }


    default:
      return state;
  }
};

export default adsReducer;
